<template>
    <div class="row patient_chart">
        <Sidebar></Sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history enrollment_history">
            <div class="table-responsive enrlmnt_hstry-table-data">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="hover_unset w-65px cursor-default"></th>
                            <th scope="col" class="img-fluid enrollment-date" @click="getEnrollmentHistoryOrderBy('enrollment_date', 'enrollment-date')">Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="img-fluid enrollment-provider"  @click="getEnrollmentHistoryOrderBy('enrollment_provider', 'enrollment-provider')">Provider<img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="img-fluid enrollment-roster-status" @click="getEnrollmentHistoryOrderBy('enrollment_roster_status', 'enrollment-roster-status')">Roster Status <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="img-fluid enrollment-roster-start-date" @click="getEnrollmentHistoryOrderBy('enrollment_roster_date', 'enrollment-roster-start-date')">Roster Start Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="img-fluid enrollment-termination-date" @click="getEnrollmentHistoryOrderBy('enrollment_termination_date', 'enrollment-termination-date')">Termination Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="img-fluid enrollment-termination-reason" @click="getEnrollmentHistoryOrderBy('enrollment_termination_reason', 'enrollment-termination-reason')">Termination Reason <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <slot v-if="enrollmentData.length > 0" >
                            <tr v-for="(item, index) in enrollmentData" :key="index">
                                <td><span class="rectangle"></span></td>
                                <td>{{ $filters.inputDateTime(item.created_datetime, 'YYYY-MM-DD') }}</td>
                                <td>{{ item.provider_name }}</td>
                                <td>{{ item.enrolment_status }}</td>
                                <td>{{ item.enrolment_start_date }}</td>
                                <td>{{ item.enrolment_termination_date }}</td>
                                <td>{{ item.termination_reason }}</td>
                            </tr>
                        </slot>
                        <slot v-else>
                            <tr>
                                <td colspan="5">No records found.</td>
                            </tr>
                        </slot>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import $ from "jquery";
    import Sidebar from '../Sidebar.vue';
    import axios from "@/scripts/axios.js";

    export default {
        data() {
            return {
                enrollmentData:[]
            }
        },
        components: { Sidebar },
        methods: {
            getEnrollmentHistoryOrderBy(param, className) {
                var orderBy = 'desc';
                if($('.'+className).hasClass('active'))
                {
                    $('.'+className).removeClass('active');
                    orderBy = 'desc';
                }
                else
                {
                    $('.'+className).addClass('active');
                    orderBy = 'asc';
                }
                const url = "patient/provider-status/order-by";
                const patient_id = this.$route.params.patient_id;
                let $this = this;
                axios.post(url, { patient_id: patient_id, filter: param, required_order: orderBy})
                    .then((response) => {
                        if (response.status == 200) {
                            $this.enrollmentData = response.data.data;
                        }
                    });
                
            },
            getEnrollmentData() {
                this.$store.state.loader = true;
                const url = "/patient/provider-status/list";
                const patient_id = this.$route.params.patient_id;
                let $this = this;
                axios.post(url, { patient_id: patient_id })
                    .then((response) => {
                        if (response.status == 200) {
                            $this.enrollmentData = response.data.data;
                            this.$store.state.loader = false;
                        } else {
                            this.$store.state.loader = false;
                        }
                    }).catch(error => {
                        this.$filters.moshaToast(error.message, "error")
                        this.$store.state.loader = false;
                    });
            },
        },
        mounted() {
            this.getEnrollmentData()
        },
    }
</script>