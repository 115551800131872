<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Immunizations" v-model="form.keyword" @input="handleInput" @keydown.enter="immunizationSearch" ref="immunizationSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length }}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="fetchPatientPrevention"></CustomFilter>
                    </span>
                </div>
                <button class="comman_btn" @click="immunizationCreate"> Add Immunization </button>
            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="hover_unset w-50px"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'date' }"  @click="changeSort('date')">Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'type' }"  @click="changeSort('type')"> Vaccine Name <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'brand_name' }"  @click="changeSort('brand_name')"> Brand Name <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset"> Provider</th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.preventionList" :key="index">
                            <td></td>
                            <td>{{ item.date }}</td>
                            <td>{{ item.type }}</td>
                            <td>{{ item.brand_name }}</td>
                            <td>{{ item.provider_name }}</td>
                            <td class="edit_delet_link"><span class="popup_link" @click="immunizationview(item.id)">View</span><span class="popup_link" @click="deleteRecord(item.id)">Delete</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal fade" id="Immunization_view_model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl" style="width: calc(var(--scale-ratio) * 1190px);">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="cancelImmunization"></button>
                    <div class="box">
						<h2 class="popup_title">{{this.immunizationLbl}}</h2>
                        <div class="col-7 m-auto">
                            <div class="d-flex justify-content-between readonly-form">
                                <div class="vaccinesearch-immun">
                                    <div class="search">
                                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                                        <input type="text" @keyup="vaccineSearch" class="form-control" placeholder="Search Vaccine" v-model="vaccine_search" ref="vaccineSearch" @focus="vaccineSearch">
                                        <div class="search_drop_box new_big_drop" v-if="vaccineList.length > 0 ">
                                            <slot v-for="(item, index) in this.vaccineList" :key="index">
                                                <div class="con_drop_line p-0" @click="selectVaccine(item)">
                                                    <div class="man_haeding">
                                                        {{ item.name }}
                                                    </div>
                                                </div>
                                            </slot>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="Selact_drodwn">
                                        <div class="dropdown-div" @click="toggleStatusList">
                                            <div class="form-control caption"> {{ (form.status != null) ?
                                                form.status : 'Status' }} <img src="/images/down-arrow-new.svg"
                                                    class="img-fluid"></div>
                                            <div class="list mt-0" v-show="showStatusList">
                                                <div v-for="(item, index) in this.statusOpt" :key="index" class="item"
                                                    @click="selectStatus(item)">
                                                    {{ item.title }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row custom-form">
                                <div class="col-6">
                                    <div class="input_box">
                                        <input type="text" class="form-control form-control-lg" v-model="form.date" required readonly>
                                        <label class="form-label">Date<small class="asterisksign">*</small> </label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input_box immunization_provider_dropdown">
                                        <CustomDropDown :options="mrpProviderDataSource" :initialValue="this.provider_dropdown_text"
                                            v-model="form.provider_id" @item-selected="handleItemSelected"
                                            fieldName="referring_practitioner_id" id="referring_practitioner_id">
                                        </CustomDropDown>
                                        <ValidationErrorMessageList :errors="v$.form.provider_id.$errors" />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input_box">
                                        <input type="text" class="form-control form-control-lg" required v-model="form.type">
                                        <label class="form-label">Vaccine Name<small class="asterisksign">*</small> </label>
                                        <ValidationErrorMessageList :errors="v$.form.prevention_id.$errors" />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input_box">
                                        <input type="text" class="form-control form-control-lg" required v-model="form.brand_name">
                                        <label class="form-label">Brand Name<small class="asterisksign">*</small> </label>
                                        <ValidationErrorMessageList :errors="v$.form.brand_name.$errors" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input_box">
                                        <input type="text" class="form-control form-control-lg" required v-model="form.code">
                                        <label class="form-label">DIN <small class="asterisksign">*</small></label>
                                        <ValidationErrorMessageList :errors="v$.form.code.$errors" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input_box">
                                        <input type="number" class="form-control form-control-lg" required v-model="form.lotNumber">
                                        <label class="form-label">Lot <small class="asterisksign">*</small> </label>
                                        <ValidationErrorMessageList :errors="v$.form.lotNumber.$errors" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input_box">
                                        <input type="text" class="form-control form-control-lg mb-0" required v-model="form.dose">
                                        <label class="form-label">Dose<small class="asterisksign">*</small> </label>
                                        <ValidationErrorMessageList :errors="v$.form.dose.$errors" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input_box">
                                        <input type="text"  class="form-control form-control-lg mb-0" required v-model="form.route">
                                        <label class="form-label">Route</label>
                                    </div>
                                </div>
                                <div class="text_area">
                                    <label  class="txt_label">Instructions</label>
                                    <textarea class="form-control" rows="3" v-model="form.instructions"></textarea>
                                </div>
                                <div class="text_area">
                                    <label  class="txt_label">Comments</label>
                                    <textarea class="form-control" rows="3" v-model="form.notes"></textarea>
                                </div>
                                <div class="mt-5 mb-4 text-center">
                                    <button class="comman_brdr_btn big_btn mx36" @click="cancelImmunization"> Cancel </button>
                                    <button class="comman_btn big_btn mx36" @click="saveImmunization"> {{ this.lblText }} <img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
                                </div>
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from '../sidebar.vue';
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';
// import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import CustomFilter from '../../../base/CustomFilter.vue';
import CustomDropDown from '../../../base/formFields/CustomDropDown.vue';
import $ from "jquery";

export default ({
    setup: () => {
        return { 
            v$: useVuelidate()
        };
    },
    validations() {
        return this.$store.state.prevention.validationRules;
    },
    data() {
        return {
            defaultPreventionsData:[],
            vaccineList:[],
            vaccine_search:'',
            lblText:'Create',
            statusText:'Completed',
            statusOpt:[
                { value: "Completed", title: "Completed" },
                { value: "Refused", title: "Refused" },
                { value: "Ineligible", title: "Ineligible" },
            ],
            showStatusList: false,
            preventionList: [],
            loader: false,
            filterList:[],
            isShowCustomFilter:false,
            filterOptions:[
                { value: "received_timerange", title: "Date" },
                { value: "patient_immunizations.type", title: "Vaccine Name" },
                { value: "patient_immunizations.brand_name", title: "Brand Name" },
                { value: "provider_name", title: "Provider" },
            ],
            immunizationLbl : 'Add New Immunization',
            searchTimeout: null,
            mrpProviderDataSource: [],
            provider_dropdown_text: 'Select Provider',
            isSelected: false
        }
    },
    components: {
        sidebar,
        ValidationErrorMessageList,
        // 'ejs-datepicker': DatePickerComponent,
        CustomFilter,
        CustomDropDown
    },
    mounted() {
        this.resetModelForm()
        document.addEventListener('click', this.handleClickOutside);
        this.fetchPatientPrevention()
        this.fetchProviderData()
    },
    methods:{
        handleInput() {
            // Clear the previous timer if it exists
            clearTimeout(this.searchTimeout);

            // Start a new timer that triggers after 2 seconds of idle time
            this.searchTimeout = setTimeout(() => {
                this.immunizationSearch();
            }, 1000); // 1-second idle time
        },
        immunizationCreate(){
            this.resetModelForm()
            this.immunizationLbl = 'Add New Immunization'
            this.lblText = 'Create'
            this.form.provider_id = ''
            this.provider_dropdown_text = 'Select Provider'
            if(localStorage.getItem('authUserRole') === 'Doctor' || localStorage.getItem('authUserRole') === 'Nurse'){
                this.form.provider_id = localStorage.getItem('userId')
                this.provider_dropdown_text = localStorage.getItem('authUserName');
                this.isSelected = true
            }
            window.$("#Immunization_view_model").modal("show");
        },
        immunizationview(id){
            this.resetModelForm()
            this.edit(id)
            this.lblText = 'Save'
            this.immunizationLbl = 'Edit Immunization'
            this.form.provider_id = 0;
            this.provider_dropdown_text = 'Select Provider'
            window.$("#Immunization_view_model").modal("show");
        },
        vaccineSearch() {
            axios.post("json_autocomplete/immunization/list", { search_text: this.vaccine_search })
                .then((response) => {
                    this.vaccineList = response.data.data;
            })
        },
        selectVaccine(item) {
            this.vaccine_search = ''
            this.vaccineList = [];
            this.$store.state.prevention.form.type = item.name
            this.$store.state.prevention.form.prevention_id = item.id
            // this.$store.state.prevention.form.brand_name = item.brand_name
        },
        cancelImmunization(){
            window.$("#Immunization_view_model").modal("hide");
            const queryId = this.$route.query.id;
            if (queryId && Number(queryId) > 0) {
                this.$router.push({ name: 'PatientEChartImmunizationList', params: {patient_id: this.$route.params.patient_id} });
            }
        },
        saveImmunization(){
            this.v$.$validate();
            const $this = this
            if (!this.v$.$error) {
                this.form.patient_id = this.$route.params.patient_id
                this.form.is_selected = this.isSelected
                $this.loader = true
                let url
                if (this.$store.state.prevention.form.id) {
                    url = "patient/immunization/update";
                } else {
                    url = "patient/immunization/store";
                }

                axios.post(url, this.form)
                    .then((response) => {
                    if (response.status == 200) {
                        this.loader = false
                        const queryId = this.$route.query.id;
                        if (queryId && Number(queryId) > 0) {
                            $this.cancelImmunization()
                            this.$router.push({ name: 'PatientEChartImmunizationList', params: {patient_id: this.$route.params.patient_id} });
                        } else {
                            setTimeout(function () {
                                $this.fetchPatientPrevention()
                                $this.$filters.moshaToast(response.data.message, "success")
                                $this.cancelImmunization()
                            }, 400);
                        }

                    } else {
                        this.loader = false
                        this.$filters.moshaToast(response.data.message, "error")
                    }
                })

            }else {
					for (const [key] of Object.entries(this.v$.form)) {
						if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
				}
        },
        resetModelForm(){
            Object.assign(
				this.$store.state.prevention.form,
				this.$store.state.prevention.defaultFormData
			);
            this.v$.$reset();
        },
        selectStatus(item){
            this.$store.state.prevention.form.status = item.value
        },
        toggleStatusList() {
			this.showStatusList = !this.showStatusList;
		},
        fetchPatientPrevention() {
            this.$store.state.loader = true;
			this.preventionList = []
			axios.post("patient/immunization/list", {
                'patient_id':this.$route.params.patient_id,
                'keyword' : this.form.keyword,
                advanceFilter:this.form.advanceFilter,
                sort_by: this.sortBy,
                sort_order:this.sortOrder,
            })
            .then((response) => {
                this.$store.state.loader = false;
                this.preventionList = response.data.data
            })
		},
        immunizationSearch(){
            // Clear the previous timer if it exists
            clearTimeout(this.searchTimeout);
            this.fetchPatientPrevention()
        },
        addFilter(){
            this.isShowCustomFilter = !this.isShowCustomFilter;
        },
        changeSort(fieldName){
            if (fieldName != this.sortBy) {
                this.sortOrder = 'desc'
            } else if (this.sortOrder == 'desc') {
                this.sortOrder = 'asc'
            } else{
                this.sortOrder = 'desc'
            }

            this.sortBy = fieldName

            this.fetchPatientPrevention()
        },
        deleteRecord(id) {
            this.$swal({
                title: 'Are you sure to DELETE this record?',
                text: '',
                icon: '',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                closeOnConfirm: false,
                closeOnCancel: false,
                reverseButtons: true, // This will swap the Confirm and Cancel buttons
                    customClass: {
                        container: 'my-swal-container',
                        popup: 'my-swal-popup delete-popup',
                        header: 'my-swal-header',
                        title: 'my-swal-title',
                        closeButton: 'my-swal-close-button',
                        icon: 'my-swal-icon',
                        image: 'my-swal-image',
                        content: 'my-swal-content',
                        input: 'my-swal-input',
                        actions: 'my-swal-actions',
                        confirmButton: 'my-swal-confirm-button',
                        cancelButton: 'my-swal-cancel-button',
                        footer: 'my-swal-footer'
                    }
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "patient/immunization/delete";
                    axios.post(url, {'id':id, 'patient_id':this.$route.params.patient_id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.fetchPatientPrevention()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });
        },
        edit(id){
            const url = "patient/immunization/retrieve";
                axios.post(url, { id: id })
                .then((response) => {
                    if (response.status == 200) {
                        this.$store.state.prevention.form = response.data.data

                        if(response.data.data.provider_id){
                            this.form.provider_id = response.data.data.provider_id
                            this.provider_dropdown_text = response.data.data.provider_name
                        }
                    }
                })
        },
        handleCustomField(values){
            this.filterList = values
            let advanceFilter={}
            values.forEach(item => {
                advanceFilter[item.field] = item.value
            });
            this.form.advanceFilter = advanceFilter
        },
        handleClickOutside(event) {
            if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
                this.fetchPatientPrevention()
                this.isShowCustomFilter = false;
            }

            const vaccineSearchEle = this.$refs.vaccineSearch;
            if (vaccineSearchEle && !vaccineSearchEle.contains(event.target) && !event.target.closest(`.search`)) {
                this.vaccineList = []
            }
 
        },
        async fetchProviderData() {
			var $this = this
			axios.post("json_list/provider", { roleId: [2,3] })
				.then((response) => {
					const providerTempData = [];
					const details = response.data.data;
					$.each(details, function (key, detail) {
						if (!($this.$route.name == "PatientEChartConsultAdd" && detail.deleted_datetime)) {
							providerTempData.push({
								value: detail.user_id,
								title: detail.full_name,
							})
						}
					})
					this.mrpProviderDataSource = providerTempData
				})
		},
        handleItemSelected(item) {
          if(item.fieldName === 'referring_practitioner_id') {
            this.form.provider_id = item.item.value
            this.provider_dropdown_text = item.item.title
            this.isSelected = true
          }
        }
    },
    computed: {
        form() {
			return this.$store.state.prevention.form;
		}
    },
    created(){
        this.$store.state.loader = true;
        const queryId = this.$route.query.id;
        if (queryId && Number(queryId) > 0) {
            setTimeout(() => {
                this.immunizationview(Number(queryId))
            }, 1000);
        }
    },
})
</script>
