<template>
	<div class="row patient_chart">
		<Sidebar></Sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 edit_record_data master-view-record">
			<div class="row" v-if="user_info">
				<div class="col-12 edit_record chart-button-edit">
					<router-link :to="{ name: 'PatientEdit',params: { patient_id: user_info.patient_id,  }}"> Edit Record</router-link>
					<!-- <router-link :to="{ name: 'PatientEdit',params: { patient_id: user_info.patient_id,  }}" class="float-end"> Print Label</router-link> -->
					<a href="javascript:void(0);" class="float-end" @click="openChartPrintLabel(user_info.patient_id)">Print Label</a>
                </div>
			</div>
			<div class="scrlbar">
				<div class="row justify-content-center" v-if="user_info">
					<div class="col-12 col-sm-6 col-md-6 col-lg-6 master-record-first-col">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title mt-0">Demographic</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Title</label> <span>{{ user_info.title }}</span></p>
								<p><label>Last Name</label> <span>{{ user_info.last_name }}</span></p>
								<p><label>Middle Name</label> <span>{{ user_info.middle_name }}</span></p>
								<p><label>First Name</label> <span>{{ user_info.first_name }}</span></p>
								<p><label>Suffix</label> <span>{{ user_info.suffix }}</span></p>
								<p><label>Gender</label>									
									<span>
										<slot v-if="user_info.gender == 'M'">Male</slot>
										<slot v-else-if="user_info.gender == 'F'">Female</slot>
										<slot v-else-if="user_info.gender == 'T'">Trans</slot>
										<slot v-else-if="user_info.gender == 'O'">Other</slot>
									</span>
								</p>
								<div class="two_inputs">
									<p><label>Date Of Birth</label> <span class="white-space-nowrap">{{ user_info.dob }}</span></p>
									<p class="demo-age-line"><label>Age</label> <span>{{ $filters.patientCalculatedAge(user_info.dob,true) }}</span></p>
								</div>
								<p><label>Spoken Language</label> 
								<span>
									<slot v-if="user_info.spoken_language == 'ENG'">English</slot>
									<slot v-else-if="user_info.spoken_language == 'FRE'">French</slot>
									<slot v-else-if="user_info.spoken_language == 'OTH'">Other</slot>
								</span>
								</p>
								<p><label>Chart ID</label> <span>{{ user_info.chart_id }}</span></p>
							</div>
						</div>
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">Health Insurance 
								<!-- <div class="ms-5 icon_tooltip remove_tooltip_arrow" v-if="!user_info.health_insurance_no">
									<Popper placement="bottom" :content="healthInsToolTip" hover arrow>
										<span class="qua_icon un_elg">?</span>
									</Popper>
								</div> -->
								<div class="icon_tooltip leftalign_tooltip_arrow" v-if="user_info.is_hcv_verified">
									<Popper placement="bottom" :content="healthCardEligible" hover arrow>
										<!-- <span class="qua_icon ps_elg">?</span> -->
										<img src="/images/chack-icon-green.svg" style="width: calc(var(--scale-ratio) * 35px);">
									</Popper>
								</div>
								<div class="icon_tooltip remove_tooltip_arrow" style="" v-if="user_info.is_hcv_verified === 0 && user_info.health_insurance_no != null && user_info.health_card_type == 'ON'">
									<Popper placement="bottom" :content="healthCardNotEligible" hover arrow>
										<!-- <span class="qua_icon nt_elg"></span> -->
										<img src="/images/close-icon-red.svg" style="width: calc(var(--scale-ratio) * 35px);">
									</Popper>
								</div>
								</h4>	
							</div>
							<div class="box-body containt_detail">
								<div class="two_inputs">
									<p><label>Health Card Number </label><span class="white-space-nowrap">{{ user_info.health_insurance_no }}</span></p>
									<p><label>Ver.</label><span>{{ user_info.health_card_ver }}</span></p>
								</div>
								<p><label>Health Card Province </label><span>{{ user_info.health_card_type }}</span></p>
							</div>
						</div>
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">Clinic Status</h4>
							</div>
							<div class="box-body containt_detail">
								<p>
									<label>Patient Status</label>
									<span>
										<slot v-if="user_info.status == 'AC'">Active</slot>
										<slot v-else-if="user_info.status == 'IN'">Inactive</slot>
										<slot v-else-if="user_info.status == 'DE'">Deceased</slot>
									</span>
								</p>
								<p><label>Patient Status Date</label> <span>{{ user_info.patient_status_date }}</span></p>
								<p>
									<label>Roster Status</label>
									<span>{{ user_info.roster_status }}</span>
								</p>
								<p>
									<label>Roster Start Date</label>
									<span>{{ user_info.enrolment_start_date }}</span>
								</p>
								<p>
									<label>Roster Termination Date</label>
									<span>{{ user_info.enrolment_termination_date }}</span>
								</p>
								<p>
									<label>Roster Termination Reason</label>
									<span>{{ user_info.termination_reason }}</span>
								</p>
								<p>
									<label>Primary Physician</label> 
									<span>{{ this.$filters.strToUpperCase(user_info.primary_physician) }}</span>
								</p>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-6 master-record-second-col">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title mt-0">Contact Information</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Cell Phone </label> <span>{{ user_info.cell_phone }} </span></p>
								<p><label>Email </label> <span>{{ user_info.email }}</span></p>
								<p><label>Residential Address </label> <span>{{ user_info.residential_address }}</span></p>
								<p><label>Residential City </label> <span>{{ user_info.residential_city }}</span></p>
								<p><label>Residential Province </label> <span>{{ user_info.residential_state }}</span></p>
								<p><label>Residential Country </label> <span>{{ user_info.residential_country }}</span></p>
								<p><label>Residential Postal </label> <span>{{ user_info.residential_zip }}</span></p>
								<p><label>Home Phone</label> <span>{{ user_info.home_phone }}</span></p>
								<div class="two_inputs">
									<p><label>Work Phone</label> <span class="white-space-nowrap">{{ user_info.work_phone }}</span></p>
									<p><label>Ext</label><span>{{ user_info.work_phone_ext }}</span></p>
								</div>
								<p class="address_line mailing-address-with-checkbox">
									<label class="border-0 p-0">Mailing Address Same as Residential</label>
									<span class="ms-3 p-0 w-auto">
										<input class="form-check-input disable_input m-0 align-middle" type="checkbox" id="checkboxNoLabel" 
										:checked="user_info.is_same_residential_mailing" 
										:disabled="true" :class="{'check_ntdsbl': !user_info.is_same_residential_mailing}"
										>
										<!-- <input class="form-check-input disable_input m-0 align-middle" type="checkbox" id="checkboxNoLabel" v-else disabled> -->
									</span>
								</p>

								<slot v-if="!user_info.is_same_residential_mailing">
								<p><label>Mailing Address </label> <span>{{ user_info.mailing_address }}</span></p>
								<p><label>Mailing City </label> <span>{{ user_info.mailing_city }}</span></p>
								<p><label>Mailing Province </label> <span>{{ user_info.mailing_state }}</span></p>
								<p><label>Mailing Country </label> <span>{{ user_info.mailing_country }}</span></p>
								<p><label>Mailing Postal </label> <span>{{ user_info.mailing_zip }}</span></p>
								</slot>

							</div>
						</div>
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">ANNOTATIONS</h4>
							</div>
							<div class="box-body containt_detail">
								<p class="align-items-start mb-3"><label>Notes</label> <span class="l-h-n">{{ user_info.notes }}</span></p>
								<p class="align-items-start mb-3"><label>Alerts</label> <span>{{ user_info.alert }}</span></p>
							</div>
						</div>	
					</div>
				</div>

				<div class="row" v-if="user_info">
					<div class="col-12 col-sm-6 col-md-6 col-lg-6 master-record-first-col">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">CLINICAL CARE TEAM</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Family Physician Last Name</label> <span>{{ user_info.family_physician_last_name }}</span></p>
								<p><label>Family Physician First Name </label> <span>{{ user_info.family_physician_first_name }}</span></p>
								<p><label>Family Physician Phone </label> <span>{{ user_info.family_physician_phone }}</span></p>
								<p><label>Family Physician Fax </label> <span>{{ user_info.family_physician_fax }}</span></p>
								<p><label>Family Physician Address </label> <span>{{ user_info.family_physician_address }}</span></p>
								<p><label>Family Physician City </label> <span>{{ user_info.family_physician_city }}</span></p>
								<p><label>Family Physician Province </label> <span>{{ user_info.family_physician_state }}</span></p>
								<p><label>Family Physician Country </label> <span>{{ user_info.family_physician_country }}</span></p>
								<p><label>Family Physician Postal </label> <span>{{ user_info.family_physician_zip }}</span></p>
							</div>
						</div>	
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-6 master-record-second-col">
						<div class="box box-bordered ">
							<div class="box-header with-border opacity-0">
								<h4 class="box-title">CLINICAL CARE TEAM</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Referred Physician Last Name </label> <span>{{ user_info.referred_physician_last_name }}</span></p>
								<p><label>Referred Physician First Name </label> <span>{{ user_info.referred_physician_first_name }}</span></p>
								<p><label>Referred Physician Phone </label> <span>{{ user_info.referred_physician_phone }}</span></p>
								<p><label>Referred Physician Fax </label> <span>{{ user_info.referred_physician_fax }}</span></p>
								<p><label>Referred Physician Address </label> <span>{{ user_info.referred_physician_address }}</span></p>
								<p><label>Referred Physician City </label> <span>{{ user_info.referred_physician_city }}</span></p>
								<p><label>Referred Physician Province </label> <span>{{ user_info.referred_physician_state }}</span></p>
								<p><label>Referred Physician Country </label> <span>{{ user_info.referred_physician_country }}</span></p>
								<p><label>Referred Physician Postal </label> <span>{{ user_info.referred_physician_zip }}</span></p>
							</div>
						</div>
					</div>
				</div>

				<div class="row" v-if="user_info && this.altContactInfo.length > 0">
					<slot v-for="(item,index) in this.altContactInfo" :key="index">
						<div class="col-12 col-sm-6 col-md-6 col-lg-6 " :class="{ 'master-record-first-col': index==0, 'master-record-second-col': index==1}">
							<div class="box box-bordered ">
								<div class="box-header with-border">
									<h4 class="box-title" :class="{ 'opacity-0': index > 0 }">ALTERNATIVE CONTACT</h4>
								</div>
								<div class="box-body containt_detail" :class="{ 'right_collumn': shouldHighlightRow(index) }">
									<p><label>Contact {{ index + 1 }} Last Name</label> <span>{{ item.last_name }}</span></p>
									<p><label>Contact {{ index + 1 }} First Name</label> <span>{{ item.first_name }}</span></p>
									<p><label>Contact {{ index + 1 }} Purpose</label> 
										<span>
											<slot v-if="item.contact_purpose == 'SDM'">Substitute Decision Maker</slot>
											<slot v-else-if="item.contact_purpose == 'EC'">Emergency Contact</slot>
										</span>
									</p>
									<p><label>Contact {{ index + 1 }} Relationship</label> 
										<span>{{ item.relationship }}</span></p>
									<p><label>Contact {{ index + 1 }} Cell Phone</label> <span>{{ item.cell_phone }}</span></p>
									<p><label>Contact {{ index + 1 }} Home Phone</label> <span>{{ item.residence_phone }}</span></p>
									<div class="two_inputs">
										<p><label>Contact {{ index +1 }} Work Phone</label> <span>{{ item.work_phone }}</span></p>
										<p><label>Ext</label><span>{{ item.work_phone_ext }}</span></p>
									</div>
									<p><label>Contact {{ index + 1 }} Email</label><span>{{ item.email }}</span></p>
									<p><label>Contact {{ index + 1 }} Note</label> <span>{{ item.contact_note }}</span></p>
								</div>
							</div>
						</div>
					</slot>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import { mapState } from 'vuex';
import Sidebar from './Sidebar.vue';
import axios from "@/scripts/axios.js";
import Popper from "vue3-popper";

export default {
	emits: ['trigger-print-label-modal'], // Declare the emitted event
	data() {
		return {
			user_info: null,
			altContactInfo: [],
			healthInsToolTip: "Health Card Eligibility Unknown(Click To Verify)",
			healthCardEligible: 'Health Card Passed Validation',
			healthCardNotEligible: 'Health Card Invalid/ Out Of Province',
		}
	},
	components: { 
		Sidebar,
		Popper,
	},
	async created() {
		this.$store.state.loader = true;
		let user_info = await axios.post("patient/retrieve", {  id: this.$route.params.patient_id });
		this.user_info = user_info.data.data;
		this.getOtherContact()
		window.addEventListener("message", (event) => {
			if (event.data === "Alt contact is updated") {				
				this.getOtherContact();
			} 
		});
		this.$store.state.loader = false;
	},
	computed: {
		...mapState(['patientId'])
	},
	watch: {

	},
	methods: {
		openChartPrintLabel( patientId) {
			this.$emit('trigger-print-label-modal', patientId);
		},
		getOtherContact(){
			axios.post(`/patient/alternative-contact/list`, { 'patient_id': this.$route.params.patient_id})
				.then((response) => {
					if (response.data.status === 200) {
						this.altContactInfo = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$filters.moshaToast(error.message, "error");
				});
		},
		createContact(){
			const url = this.$router.resolve({
				path: `/patient/alter-native-contact/${this.$route.params.patient_id}/create`,
			}).href;

			window.open(url, '_blank');
        },
		editContact(id){
			const url = this.$router.resolve({
				path: `/patient/alter-native-contact/${this.$route.params.patient_id}/retrieve/${id}`,
			}).href;

			window.open(url, '_blank');
        },
		contactList(){
			const url = this.$router.resolve({
				path: `/patient/alter-native-contact/${this.$route.params.patient_id}`,
			}).href;

			window.open(url, '_blank');
        },
		enrollmentHistory(){
			const url = this.$router.resolve({
				path: `/patient/enrollment-history/${this.$route.params.patient_id}`,
			}).href;

			window.open(url, '_blank');
		},
		dateFormat(date){
			if (date == '' || date == null) {
				return ''
			}
			const dateObject = new Date(date);
			const options = { year: 'numeric', month: 'long', day: 'numeric' };

			// Adjust the options based on your desired date format
			return dateObject.toLocaleDateString('en-US', options);
		},
		getAltContactClass(index){
			return index % 2 === 0 ? 'me-1' : 'me-4';
		},
		shouldHighlightRow(index) {
				// Return true for rows with indices 1, 3, 5, 7, ...
			return (index % 2 !== 0);
		},
	}
}
</script>