<template>
    <div class="viewprescription">
        <div class="addprescription">

            <div class="search search_drug_listing" @click.stop>
                <img class="serch_icon" src="/images/search-icon.svg" alt="">
                <div>
                    <input 
                        type="text" 
                        @input="debouncedDrugSearch" 
                        @click="toggleDropdown(true)" 
                        class="form-control" 
                        placeholder="Search Drug" 
                        v-model="drug_search" 
                        ref="drugSearch"
                    >
                    <div>
                        <img 
                            src="images/loader.gif" 
                            style="width: calc(var(--scale-ratio) * 18px);right: 10px;top: calc(var(--scale-ratio) * 0px);bottom: 0;position: absolute;margin: auto;" 
                            class="loader" 
                            v-if="drugSearchLoader" 
                        />
                    </div>
                </div>

                <div class="search_drop_box" v-if="showDropdown && drugList.length > 0 && drug_search.length > 0">
                    <slot v-for="(item, index) in drugList" :key="index">
                        <div class="con_drop_line" @click="selectDrug(item)">
                            <div class="man_haeding">
                                {{ item.name }}  
                                <span class="drug-acet-mg d-block mt-5p">{{ item.ingredients }}</span>
                            </div>
                        </div>
                    </slot>
                </div>
            </div>
            <div class="btn_part">
                <button class="comman_brdr_btn big_btn" @click="cancelFrm"> Cancel </button>
                <button class="comman_btn big_btn" @click="this.save('save')" v-if="this.prescriptionList.length > 0"> Save <img src="images/loader.gif" v-if="savingloader" style="width:calc(var(--scale-ratio) * 18px);" /></button>
                <button class="comman_btn big_btn" @click="this.save('save_print')" v-if="this.prescriptionList.length > 0"> Save & Print/Fax <img src="images/loader.gif" v-if="savingPrintloader" style="width:calc(var(--scale-ratio) * 18px);" /></button>
            </div>
        </div>
        <div class="addprescription_line" style="height: 100vh;" v-if="this.prescriptionList.length == 0">Search Drug to Add Prescription</div>

        <div>
            <div class="prescription_box_scroll">
                <div class="prescription_box">
                    <div class="row" v-if="this.prescriptionList.length != 0">
                        <div class="col-md-7">
                            <div class="prescrip-list-left-scroll">
                                <slot v-for="(item, index) in prescriptionList" :key="index">
                                    <div class="left_prescription mb-20">
                                        <div class="haeding_line d-flex align-items-center justify-content-between">
                                            <div class="d-flex">
                                                <aside>Prescription {{ index+1 }}</aside>
                                                <p class="mb-0 popup_link ml-40" @click="showPrescriptionDetails(item)">Details</p>
                                            </div>
                                            <span class="popup_link" @click="removeItem(item.id)">Cancel</span>
                                        </div>
                                        <div class="mr-30">
                                            <div class="mb-0 row pd-bottom">
                                                <label for="text" class="col-sm-4 col-form-label right-bor">Generic Name</label>
                                                <div class="col-sm-8"><input type="text" class="form-control" v-model="item.generic_name" @change="generateNotes(item)"></div>
                                            </div>
                                            <div class="mb-10 row">
                                                <label for="text" class="col-sm-4 col-form-label right-bor">Brand Name</label>
                                                <div class="col-sm-8"><input type="text" class="form-control" v-model="item.drug_name" @change="generateNotes(item)"></div>
                                            </div>
                                            <div class="mb-10 row align-items-center l-h-n">
                                                <label for="text" class="col-sm-4 col-form-label right-bor mt-30">Instruction</label>
                                                <div class="col-sm-8">
                                                    <div class="pr-25">
                                                        <div class="row">
                                                            <div class="col-6 col-sm-4 col-md-3 col-lg-3">
                                                                <div class="presxrpt-int-drop">
                                                                    <label for="text" class="drop_label mb-5p">Method</label>
                                                                    <CustomDropDown :options="method_option" :initialValue="this.method_text"
                                                                        v-model="item.method" @item-selected="handleItemSelected" :fieldName="`${index}.method`" index="1" @change="this.calculateNextRefilDate(item)">
                                                                    </CustomDropDown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-0 row">
                                                <label for="text" class="col-sm-4"></label>
                                                <div class="col-sm-8">
                                                    <div class="pr-25">
                                                        <div class="dropdown_list_ins">
                                                            <div class="row">
                                                                <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                                    <div class="instru-inpt-box">
                                                                        <label for="text" class="drop_label">Dosage</label>
                                                                        <input type="number" class="form-control" v-model="item.dosage" min="0" @change="generateNotes(item)">
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                                    <div class="presxrpt-int-drop">
                                                                        <label for="text" class="drop_label">Dosage Unit</label>
                                                                        <CustomDropDown :options="dosage_unit_option" :initialValue="this.dosage_unit_text"
                                                                            v-model="item.dosage_unit" @item-selected="handleItemSelected" :fieldName="`${index}.dosage_unit`" >
                                                                        </CustomDropDown>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                                    <div class="presxrpt-int-drop">
                                                                        <label for="text" class="drop_label">Route</label>
                                                                        <CustomDropDown :options="route_option" :initialValue="this.route_text"
                                                                            v-model="item.route" @item-selected="handleItemSelected" :fieldName="`${index}.route`">
                                                                        </CustomDropDown>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                                    <div class="presxrpt-int-drop">
                                                                        <label for="text" class="drop_label">Frequency</label>
                                                                        <CustomDropDown :options="frequency_option" :initialValue="this.frequency_text"
                                                                            v-model="item.frequency" @item-selected="handleItemSelected" :fieldName="`${index}.frequency`">
                                                                        </CustomDropDown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                                    <div>
                                                                        <label for="text" class="drop_label">Duration</label>
                                                                        <input type="number" class="form-control" v-model="item.duration" @change="this.calculateNextRefilDate(item)" step="1" min="0">
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                                    <div class="presxrpt-int-drop">
                                                                        <label for="text" class="drop_label">Duration Unit</label>
                                                                        <CustomDropDown :options="duration_unit_option" :initialValue="this.duration_text"
                                                                            v-model="item.duration_unit" @item-selected="handleItemSelected" :fieldName="`${index}.duration_unit`"  @change="this.calculateNextRefilDate(item)">
                                                                        </CustomDropDown>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                                    <div>
                                                                        <label for="text" class="drop_label">Quantity</label>
                                                                        <input type="number" class="form-control" v-model="item.quantity" step="1" min="0" @change="generateNotes(item)">
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
                                                                    <div>
                                                                        <label for="text" class="drop_label">Repeats</label>
                                                                        <input type="number" class="form-control" v-model="item.repeats" step="1" min="0" @change="this.calculateNextRefilDate(item)">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="next_rfl_date mt-10" v-if="(item.is_long_term || item.repeats >=1) && item.next_refill_date">
                                            <span class="mr-10">Next Refill Date:</span>
                                            <label>{{ item.next_refill_date }}</label>
                                        </div>
                                        <div class="pl-10 pr-30">
                                            <div class="check_box">
                                                <span class="ps-0"><label>Long Term Medication</label><span class="ms-3"><input class="form-check-input m-0 align-middle" type="checkbox" v-model="item.is_long_term" @change="this.calculateNextRefilDate(item)"></span></span>
                                                <span><label>Substitution Now Allowed</label><span class="ms-3"><input class="form-check-input m-0 align-middle" type="checkbox" v-model="item.is_patient_compliant"></span></span>
                                                <span><label>Past Medication</label><span class="ms-3"><input class="form-check-input m-0 align-middle" type="checkbox" v-model="item.is_past_med"></span></span>
                                            </div>
                                            <textarea class="form-control" rows="4" v-model="item.notes"></textarea>
                                        </div>
                                        <div class="limited-codes-conf mt-50" v-if="item.drug_data && item.drug_data.LimitedUseInfo.length != 0">
                                            <p class="fs-16 fw-600 l-h-n mb-10">
                                                {{ item.drug_data.LimitedUseInfo[0].match(/Limited Use Code\(s\): \d+/) ? item.drug_data.LimitedUseInfo[0].match(/Limited Use Code\(s\): \d+/)[0] : 'Limited Use Code(s)' }}
                                            </p>
                                            <p class="fs-16 fw-300 l-h-n ml-20">
                                                {{ item.drug_data.LimitedUseInfo[0].replace(/Limited Use Code\(s\): \d+/, '').replace(/\\n/g, ' ') }}
                                            </p>
                                        </div>
                                    </div>
                                </slot>
                            </div>
                        </div>
                        <div class="col-md-5" v-if="this.prescriptionList.length != 0">
                            <div class="right_prescription pres-interactions-right mb-20" v-if="this.allergyInteractions && this.allergyInteractions.length != 0">
                                <div class="d-flex mb-20">
                                    <p class="interactions-title">Allergy Interactions</p>
                                    <span class="dosage-count d-flex align-items-center justify-content-center" v-if="this.allergyInteractions">{{  this.allergyInteractions.length  }}</span>
                                </div>
                                <div>
                                    <div class="row align-items-center mb-2">
                                        <div class="col-12 col-sm-5 col-md-5 col-lg-5 text-center">
                                            <p class="drags-title mb-0">Allergen</p>
                                        </div>
                                        <div class="col-12 col-sm-2 col-md-2 col-lg-2 text-center">
                                        </div>
                                        <div class="col-12 col-sm-5 col-md-5 col-lg-5 text-center">
                                            <p class="drags-title mb-0">Planned Drug</p>
                                        </div>
                                    </div>
                                    <div class="prescription-first-dosage" v-for="(data, index) in this.allergyInteractions" :key="index">
                                        <div>
                                            <div class="row align-items-center">
                                                <div class="col-12 col-sm-5 col-md-5 col-lg-5 text-center">
                                                    <p class="drags-name mb-0">{{  data['Allergen']  }}</p>
                                                </div>
                                                <div class="col-12 col-sm-2 col-md-2 col-lg-2 text-center">
                                                    <img src="/images/right-left-arrow.svg" class="mt-10">
                                                </div>
                                                <div class="col-12 col-sm-5 col-md-5 col-lg-5 text-center">
                                                    <p class="drags-name mb-0">{{ data['Planned Drug'] }}</p>
                                                </div>
                                            </div>
                                            <p class="drags-discription mt-10">{{  data['Warning Message']  }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="right_prescription pres-interactions-right mb-20" v-if="this.allergyInteractions && this.allergyInteractions.length == 0">
                                <div class="d-flex mb-20">
                                    <p class="interactions-title">Allergy Interactions</p>
                                </div>
                                <div class="d-flex align-items-center justify-content-center" style="height:87%;">
                                    <p class="no-allergy-found mb-0">No Allergy Interactions Found</p>
                                </div>
                            </div>
                            <div class="right_prescription pres-interactions-right mb-20" v-if="this.drugInteractions && this.drugInteractions.length != 0">
                                <div class="d-flex mb-20">
                                    <p class="interactions-title">Drug Interactions</p>
                                    <span class="dosage-count d-flex align-items-center justify-content-center" v-if="this.drugInteractions">{{ this.drugInteractions.length  }}</span>
                                </div>
                                <div>
                                    <div class="row align-items-center mb-2">
                                        <div class="col-12 col-sm-5 col-md-5 col-lg-5 text-center">
                                            <p class="drags-title mb-0">Drug 1</p>
                                        </div>
                                        <div class="col-12 col-sm-2 col-md-2 col-lg-2 text-center">
                                        </div>
                                        <div class="col-12 col-sm-5 col-md-5 col-lg-5 text-center">
                                            <p class="drags-title mb-0">Drug 2</p>
                                        </div>
                                    </div>
                                    <div class="prescription-first-dosage" v-for="(data, index) in this.drugInteractions" :key="index">
                                        <div >
                                            <div class="row align-items-center">
                                            <div class="col-12 col-sm-5 col-md-5 col-lg-5 text-center">
                                                <p class="drags-name mb-0">{{ data['Drugs Involved'].split(' and ')[0] }}</p>
                                            </div>
                                            <div class="col-12 col-sm-2 col-md-2 col-lg-2 text-center">
                                                <img src="/images/right-left-arrow.svg" class="mt-10">
                                            </div>
                                            <div class="col-12 col-sm-5 col-md-5 col-lg-5 text-center">
                                                <p class="drags-name mb-0">{{ data['Drugs Involved'].split(' and ')[1] }}</p>
                                            </div>
                                        </div>
                                        <p class="drags-discription mt-10">{{ data['Details'] }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="right_prescription pres-interactions-right mb-20" v-if="this.drugInteractions && this.drugInteractions.length == 0">
                                <div class="d-flex mb-20">
                                    <p class="interactions-title">Drug Interactions</p>
                                </div>
                                <div class="d-flex align-items-center justify-content-center" style="height:87%;">
                                    <p class="no-allergy-found mb-0">No Drug Interactions Found</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="add_prescription-bt-table" v-if="this.previousPrescriptionList.length != 0 && this.prescriptionList.length != 0">
                <div class="table-responsive prescription-medication-table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="hover_unset"><input class="form-check-input" type="checkbox"></th>
                                <th :class="{ 'active': this.sortBy == 'written_date' }" @click="changeSort('written_date')">Start Date <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                                <th :class="{ 'active': this.sortBy == 'generic_name' }" @click="changeSort('generic_name')">Medication <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                                <th :class="{ 'active': this.sortBy == 'start_date' }" @click="changeSort('start_date')">Prescriber <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                                <th class="text-center" :class="{ 'active': this.sortBy == 'is_past_med' }" @click="changeSort('is_past_med')">Past Med <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                                <th class="text-center" :class="{ 'active': this.sortBy == 'is_long_term' }" @click="changeSort('is_long_term')">Long Term <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                                <th class="hover_unset cursor-default"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in this.previousPrescriptionList" :key="index">
                                <td><input class="form-check-input" type="checkbox"></td>
                                <td>{{ item.written_date }}</td>
                                <!-- <td>
                                    <div>{{ item.name }} {{ item.dosage }} <span v-if="item.dosage_unit != null">{{ item.dosage_unit.toUpperCase() }}</span> {{ item.method }} {{  item.route  }} {{ item.frequency }} <span v-if="item.duration != null && item.duration_unit != null">for {{ item.duration }} {{ formatDurationUnit(item.duration_unit) }}</span></div>
                                    <div>Quantity: {{ item.quantity }} Repeats: {{ item.repeats }}</div>
                                </td> -->
                                <td>
                                    <div v-html="item.notes.replace(/\n/g, '<br>')"></div>
                                </td>
                                <td>{{ item.prescriber_name }}</td>
                                <td class="text-center">{{ item.is_past_med == 1 ? 'Yes' : 'No'  }}</td>
                                <td class="text-center">{{ item.is_long_term == 1 ? 'Yes' : 'No'  }}</td>
                                <td class="text-center">
                                    <span v-if="isRefillDateActive(item.next_refill_date)" class="prescription-active-link cursor-pointer">Active</span>
                                    <span class="prescription-discontinued-link cursor-pointer" v-else>Discontinued</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="adultdosing-model" tabindex="-1" style="display: none;" aria-hidden="true" v-if="modalData">
        <div class="modal-dialog modal-dialog-centered modal-xl prscptn-drug-detail-popup">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" ></button>
                    <div class="box">
                        <h2 class="drug-title mt-0 mb-30"> {{ modalData.ingredients  }} | {{ modalData.generic_name }}</h2>
                        <div class="row">
                            <div class="col-lg-6 h-100">
                                <div class="drug-dosing-details mb-20">
                                    <h3 class="drugs_name mb-20">Adultdosing</h3>
                                     <pre v-if="modalData.drug_data" style="font-family: 'Inter';">{{ modalData.drug_data.AdultDosing}}</pre>
                                </div>
                            </div>
                            <div class="col-lg-6 h-100">
                                <div class="drug-dosing-details mb-20">
                                    <h3 class="drugs_name mb-20">Pedsdosing</h3>
                                    <pre v-if="modalData.drug_data" style="font-family: 'Inter';">{{ modalData.drug_data.PedsDosing}}</pre>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 h-100">
                                <div class="drug-dosing-details mb-20">
                                    <h3 class="drugs_name mb-20">Indications</h3>
                                    <pre v-if="modalData.drug_data" style="font-family: 'Inter';">{{ modalData.drug_data.Indications}}</pre>
                                </div>
                            </div>
                            <div class="col-lg-6 h-100">
                                <div class="drug-dosing-details mb-20">
                                    <h3 class="drugs_name mb-20">Pregnancy & Lactation</h3>
                                    <pre v-if="modalData.drug_data" style="font-family: 'Inter';">{{ modalData.drug_data.PregnancyAndLactation}}</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="prescription-fax-model" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" ></button>
                    <div class="box">
                        <div class="row patient_chart justify-content-center edit_record_page">
                            <div class="col-lg-5">
                                <h4 class="box-title mt-0">Preferred Pharmacy</h4>
                                <div class="box-body containt_detail">
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Pharmacy Name</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.pharmacy_name}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Address</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.address}} {{this.pharmacy_detail.city}} {{this.pharmacy_detail.state}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Postal Code</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.zip_code}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Fax Number</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.fax}}</div>
                                    </div>
                                    </div>
                                    <div class="mb-0 row pd-bottom">
                                    <label for="text" class="col-sm-6 col-form-label right-bor">Phone Number</label>
                                    <div class="col-sm-6">
                                        <div class="pi-info">{{this.pharmacy_detail.phone}}</div>
                                    </div>
                                    </div>
                                </div>
                                <h4 class="box-title">Signature</h4>
                                <div class="box-body containt_detail mb-3">
                                    <div id="signature-control">
                                    <div class="e-sign-heading"><span id="signdescription"></span></div>
                                        <div class="row justify-content-center">
                                            <div class="col-9 me-0" style="height: 60px;">
                                                <img :src="this.sigImgBase64" class="e-control e-signature e-lib" height="60" style="height: 100%; width: 100%;" width="429">
                                                <!-- <canvas id="signature" class="e-control e-signature e-lib" role="img" aria-label="signature" tabindex="-1" height="60" style="height: 100%; width: 100%;" width="429"></canvas> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="pdf_viewer">
                                    <canvas id="pdfCanvas" ref="pdfCanvas" style="width: 100%; height: 100%;" ></canvas>
                                </div>
                                <div class="pdf-pagination-sticky" v-if="this.documentPath && isShowPagination">
                                    <div class="query-pagination text-center" v-if="totalPages > 1">
                                        <ul class="page-pagination p-0 mt-0">
                                            <li style="margin-right: calc(var(--scale-ratio) * 40px);">
                                                <img src="/images/left-arrow-pagen.svg" class="img-fluid" v-if="currentPage > 1"
                                                    @click="goToPreviousPage">
                                            </li>
                                            <li v-if="totalPages > 1 || currentPage === 1">{{ currentPage }}</li>
                                            <li v-if="totalPages > 1">/</li>
                                            <li v-if="totalPages > 1 || currentPage === 1">{{ totalPages }}</li>
                                            <li style="margin-left: calc(var(--scale-ratio) * 40px);">
                                                <img src="/images/right-arrow-pagen.svg" class="img-fluid"
                                                    v-if="currentPage < totalPages" @click="goToNextPage">
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-else class="text-center">
                                        1/1
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="btn_part d-flex justify-content-around">
                                    <button class="comman_brdr_btn" @click="cancelModel">Cancel</button>
                                    <button class="comman_brdr_btn" @click="viewPdfInNewTab">Print</button>
                                    <button class="comman_btn" @click="sendFaxModel">Fax / Email</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade fax-exportconsultation-modal" id="add-exportconsultation-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                        <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="box">
                        <ul class="export_tabs">
                            <li :class="{ active: isFaxFormVisible }" @click="changeExportOpt('fax')">Fax</li>
                            <li :class="{ active: isEmailFormVisible }" @click="changeExportOpt('email')">Email</li>
                            <li :class="{ active: isMailFormVisible }" @click="changeExportOpt('mail')">Mail</li>
                        </ul>
                        <section class="con_detl_sec">
                            <div class="fax_menu" v-if="isFaxFormVisible">
                                <div class="row align-items-center">
                                    <label for="text" class="col-sm-4 col-form-label right-bor py-0 fs-18">Specialist(s)</label>
                                    <div class="col-sm-8 position-relative selact_dropdown cunlt_specialist_option">
                                        <selectCheckBox :dataSource="specialist_option" :labelName="tagSpecialistLabel" @clicked="selectedSpecialist" :displayDiv="false" :isUnselectAll="true" unselectLabel="None" :isShowDoctorIcon="false"></selectCheckBox>
                                    </div>
                                 </div>   

                                <div class="or_line"><span>OR</span></div>

                                <!-- disable-fax -->
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="faxForm.fax_number" @input="formatFaxNumber" @keydown="handleKeyDown">
                                        <span class="multi-rc-fax-nmber white-space-nowrap d-inline-block me-2 mt-2" v-for="(phone, index) in this.faxForm.phoneNumbers" :key="index" style="display: flex; align-items: center;">
                                            {{ phone }}
                                            <img src="/images/close-icon.svg" @click="removeSelectedFax(phone)" class="cursor-pointer ml-10" style="margin-left: calc(var(--scale-ratio) * 10px); height: calc(var(--scale-ratio) * 10px);">
                                        </span>
                                        <label class="form-label" for="typeunique_idX-2">Recipient Fax Number</label>
                                        <ValidationErrorMessageList :errors="v$.faxForm.phoneNumbers.$errors" />
                                    </div>
                                    <p class="separate-multiple-fax text-center mb-0">(use comma to separate multiple fax recipients. ex: 647777777, 647123123)</p>

                                </div>
                            </div>
                            <div class="email_menu" v-if="isEmailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_id">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Email</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_id.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_header">
                                        <label class="form-label" for="typeunique_idX-2">Email Header</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_header.$errors" />
                                    </div>
                                </div>

                            </div>
                            <div class="mail_menu" v-if="isMailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.name">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Name</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.name.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.address">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Address</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.address.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.city">
                                        <label class="form-label" for="typeunique_idX-2">Recipient City</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.city.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.state">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Province</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.state.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.zip">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Postal Code</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.zip.$errors" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn big_btn mx36" @click="closeExportModel"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="exportSubmit">Confirm
                                <img src="images/loader.gif" v-if="faxLoader"  style="width: calc(var(--scale-ratio) * 18px);"/>
                            </button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import { debounce } from 'lodash';
import axios from "@/scripts/axios.js";
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import CustomDropDown from '../../../base/formFields/CustomDropDown.vue';
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';
import $ from "jquery";
import { PDFDocument } from 'pdf-lib';

export default ({
    setup() {
        return { v$: useVuelidate() };
    },
    data(){
        return {
            drugList: [],
            drug_search:'',
            prescriptionList:[],
            method_option:[
                { value: "Take", title: "Take" },
                { value: "Apply", title: "Apply" },
                { value: "Rub Well in", title: "Rub Well in" },
            ],
            method_text:'Select',
            route_option:[
                { value: "PO", title: "PO" },
                { value: "IM", title: "IM" },
                { value: "IV", title: "IV" },
                { value: "SC", title: "SC" },
                { value: "Patch", title: "Patch" },
                { value: "Cream", title: "Cream" },
                { value: "Drops", title: "Drops" },
                { value: "INH", title: "INH" },
                { value: "Nasal Medications", title: "Nasal Medications" },
                { value: "Puff", title: "Puff" }
            ],
            route_text:"Select",
            dosage_unit_option:[
                { value: "mL", title: "mL" },
                { value: "mg", title: "mg" },
                { value: "g", title: "g" },
                { value: "ucg", title: "ucg" },
                { value: "capsule", title: "capsule" },
                { value: "tablet", title: "tablet" },
                { value: "teaspoon", title: "teaspoon" },
                { value: "tablespoon", title: "tablespoon" },
                { value: "activation", title: "activation" }
            ],
            dosage_unit_text:"Select",
            frequency_option:[
                { value: "Every Hour", title: "Every Hour" },
                { value: "Every 2 Hours", title: "Every 2 Hours" },
                { value: "Every Morning", title: "Every Morning" },
                { value: "Every Evening", title: "Every Evening" },
                { value: "Every Bedtime", title: "Every Bedtime" },
                { value: "Daily", title: "Daily" },
                { value: "2x Daily", title: "2x Daily" },
                { value: "3x Daily", title: "3x Daily" },
                { value: "4x Daily", title: "4x Daily" },
                { value: "Weekly", title: "Weekly" },
                { value: "2x Weekly", title: "2x Weekly" },
                { value: "Monthly", title: "Monthly" },
                { value: "2x Monthly", title: "2x Monthly" }
            ],
            frequency_text:"Select",
            duration_unit_option:[
                { value: "day (s)", title: "day (s)" },
                { value: "week (s)", title: "week (s)" },
                { value: "month (s)", title: "month (s)" }, 
            ],
            duration_text:"Select",
            current_date: new Date(),
            savingloader:false,
            savingPrintloader:false,
            patient_detail:[],
            pharmacy_detail:[],
            // provider_detail:[]
            documentPath: null,
            sigImgBase64:null,
            drugSearchLoader:false,
            allergyInteractionsData:[],
            drugInteractionsData:[],
            formattedTodayDate: this.getFormattedTodayDate(),
            currentUserName: this.getCurrrentUserName(),
            patientAllergyPapidCodes: [],
            userMedicationIds: [],
            previousPrescriptionList:[],
            allergyInteractions: [],
            drugInteractions: [],
            modalData: {},
            isFaxFormVisible:true,
            isEmailFormVisible:false,
            isMailFormVisible:false,
            tagSpecialistLabel: "",
            specialist_option: [],
            selectedItems:[],
            faxLoader:false,

            pdfDoc: null,
            existingPdfBytes: null,
            totalPages: 0,
            currentPage: 1,
            isShowPagination: false,
            filePath: null,
            showDropdown    : false,
        }            
    },
    components: {
        CustomDropDown,
        ValidationErrorMessageList,
        selectCheckBox,
    },
    created() {
        this.debouncedDrugSearch = debounce(this.drugSearch, 900);
    },
    methods:{
        showPrescriptionDetails(item){
            this.modalData = {};
            this.modalData = item;
            window.$("#adultdosing-model").modal("show");
        },
        drugSearch(){
            this.drugList = [];
            if (this.drug_search && this.drug_search.length > 0) {
                this.drugSearchLoader = true
				axios.post("/json_autocomplete/medication/list", { term: this.drug_search })
					.then((response) => {
						this.drugList = response.data.data;
                        this.drugSearchLoader = false
					})
			} else {
                this.drugSearchLoader = false
            }

        },
        async selectDrug(item){
            this.$store.state.loader = true;
            this.drug_search = ''
            this.drugList = []
            item.medication_id = item.id
            item.generic_name = item.ingredients
            item.drug_name = item.name
            item.allergy_interactions = [];
            item.drug_interaction = [];
            try {
                await new Promise(resolve => setTimeout(resolve, 10));
                this.prescriptionList.push(item)
                // this.getAllergyWarning()
                // this.getDrugInteractions()
            } finally {
                this.$store.state.loader = false;
            }

            await this.getApiData(item)
            await this.generateNotes(item)
        },
        toggleDropdown(state) {
            this.showDropdown = state;
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.showDropdown = false;
            }
        },
        removeItem(id) {
            const indexToRemove = this.prescriptionList.findIndex(item => item.id === id);

            if (indexToRemove !== -1) {
                this.prescriptionList.splice(indexToRemove, 1);
            }
        },
        async save(action='save'){
            let url = ''
            if (this.$route.name == 'PatientEChartPrescriptionView') {
                url = 'patient/prescriptions/update-multiple'
            } else {
                url = 'patient/prescriptions/store-multiple'
            }
            let form = {}
            form.patient_id       = this.$route.params.patient_id
            form.prescriptionList = this.prescriptionList
            if(this.validateDosage()) {
                this.changeLoaderStatus(action, true)
                await axios.post(url, form)
                    .then((response) => {
                        this.changeLoaderStatus(action, false)
                        if (response.status == 200) {
                            this.loader = false;
                            this.$filters.moshaToast(response.data.message, "success");
                            this.selectedItems = [response.data.data.id];
                            if (action == 'save_print') {
                                this.documentPath = response.data.data.patient_prescription_file;
                                if (this.documentPath) {
                                    this.modifyPdf();
                                }
                                if (this.$route.name != 'PatientEChartPrescriptionView') {
                                    this.sigImgBase64 = response.data.data.base64_sign
                                }
                                window.$("#prescription-fax-model").modal("show");
                            } else {
                                this.$router.push({ name: 'PatientEChartPrescriptionList', params: {patient_id: this.$route.params.patient_id } });
                            }

                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.changeLoaderStatus(action, false)
                        this.loader = false;
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            }
            
        },
        documentUrl(documentPath) {
			return `${process.env.VUE_APP_STORAGE_URL}/e-forms/document/view/${documentPath}`;
		},
        calculateNextRefilDate(item){
            if (item.is_long_term || item.repeats >=1) {
                let nextRefillDate = new Date(this.current_date);
                switch (item.duration_unit) {
                    case 'day (s)':
                        nextRefillDate.setDate(nextRefillDate.getDate() + parseInt(item.duration));
                        console.log(nextRefillDate)
                        break;
                    case 'week (s)':
                        nextRefillDate.setDate(nextRefillDate.getDate() + parseInt(item.duration * 7));
                        break;
                    case 'month (s)':
                        nextRefillDate.setMonth(nextRefillDate.getMonth() + parseInt(item.duration));
                        break;
                    default:
                        break;
                }
                const year = nextRefillDate.getFullYear();
                const month = String(nextRefillDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
                const day = String(nextRefillDate.getDate()).padStart(2, '0');

                item.next_refill_date = `${year}-${month}-${day}`;
            } else {
                item.next_refill_date = ''
            }
            this.generateNotes(item)
        },
        handleItemSelected(values) {
            const digit = values.fieldName.match(/^(\d+)\.(.+)/); // Regular expression to match digits at the beginning
            if (digit) {
                this.prescriptionList[parseInt(digit[1])][digit[2]] = values.item.value;
                if (digit[2] == 'duration' || digit[2] == 'duration_unit') {
                    this.calculateNextRefilDate(this.prescriptionList[parseInt(digit[1])])
                }
                this.generateNotes(this.prescriptionList[parseInt(digit[1])])
            }
        },
        validateDosage() {
            const validationMessages = {
                dosage       : "Please fill in Dosage section",
                dosage_unit  : "Please fill in Dosage Unit section",
                route        : "Please fill in Route section",
                frequency    : "Please fill in Frequency section",
                duration     : "Please fill in Duration section",
                duration_unit: "Please fill in Duration Unit section",
                quantity     : "Please fill in Quantity section",
                repeats      : "Please fill in Repeats section"
            };

            for (const item of this.prescriptionList) {
                for (const [key, message] of Object.entries(validationMessages)) {
                    if (!item[key]) {
                        this.$filters.moshaToast(message, "error");
                        return false; // Validation failed
                    }
                }
            }
            // Dosage is entered for all items
            return true; // Validation passed
        },
        changeLoaderStatus(action, status){
            if (action == 'save') {
                this.savingloader = status
            } else {
                this.savingPrintloader = status
            }
        },
        generateNotes(item){
            let notes = ''
            if (item.drug_name) {
                notes += item.drug_name
            }
            if (item.method || parseInt(item.dosage) > 0 || item.dosage_unit || item.route || parseInt(item.duration) > 0 || item.duration_unit || item.frequency) {
                notes += "\n"
                if (item.method) {
                    notes += item.method
                }
                if (parseInt(item.dosage) > 0) {
                    notes += " "+parseInt(item.dosage)
                }
                if (item.dosage_unit) {
                    notes += " "+item.dosage_unit
                }
                if (item.route) {
                    notes += " "+item.route
                }
                if (item.frequency) {
                    notes += " "+item.frequency
                }
                if (parseInt(item.duration) > 0) {
                    notes += " for "+parseInt(item.duration)
                }
                if (item.duration_unit) {
                    notes += " "+item.duration_unit
                }
            }
            if (item.quantity && parseInt(item.quantity) > 0) {
                notes += "\nQuantity: "+item.quantity
            }
            if (item.repeats && parseInt(item.repeats) > 0) {
                notes += "\nRepeats: "+item.repeats
            }
            item.notes = notes
        },
        edit() {
            axios.post("patient/prescriptions/retrieve", {id:this.$route.params.id})
                    .then((response) => {
                         if (response.status == 200) {
                              const details = response.data.data;
                              this.prescriptionList = [details];

                         } else {
                              this.$filters.moshaToast(response.data.message, "error");
                         }
                    }).catch(error => {
                         this.loader = false;
                         console.log(error)
                         if (error.response.status === 422) {
                              this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                         } else {
                              this.$filters.moshaToast(error.response.data.message, "error");
                         }
                    });
        },
        cancelFrm(){
            this.$router.push({ name: 'PatientEChartPrescriptionList', params: { patient_id: this.$route.params.patient_id} });
        },
        fetchPatientDetail() {
            let params = { 'id': this.$route.params.patient_id }
			axios.post("patient/detail", params)
				.then((response) => {
					if (response.data.status === 200) {
						this.patient_detail = response.data.data
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
        // getProvider(){
        //     axios.post("json_list/provider", { type: 'Doctor', 'id':this.form.provider_id })
        //         .then((response) => {
        //         this.provider_detail = response.data.data[0];
        //     })
        // },
        printPreview() {
            const printContent = $(".prescription_html").html();
            // Create a new window for print preview
            const printWindow = window.open('', '_blank');

            // Write the HTML content to the print window
            printWindow.document.open();
            printWindow.document.write(`
            <html>
                <head>
                <title>Print Preview</title>
                </head>
                <body>
                ${printContent}
                </body>
            </html>
            `);
            printWindow.document.close();

            // Call the print function after a short delay to ensure the content is loaded
            setTimeout(() => {
                printWindow.print();
                printWindow.close();
            }, 500);
        },
        cancelPrescription(){
            window.$("#prescription-fax-model").modal("hide");
        },
        getPreferredPharamcy(){
            axios.post("patient/get-preferred-pharamcy", { patient_id: this.$route.params.patient_id })
                .then((response) => {
                this.pharmacy_detail = response.data.data
            })
        },
        async modifyPdf() {
			try {
				if (!this.documentPath) {
					throw new Error('Document path is not defined.');
				}

                // Assuming `this.documentPath` is the base64 string without data URI prefix
                const blob = this.base64ToBlob(this.documentPath); // Convert base64 to Blob
                const url = URL.createObjectURL(blob); // Create URL for the Blob

				this.form.annotatedPdfBytes = await fetch(url).then(res => res.arrayBuffer());
				this.existingPdfBytes = this.form.annotatedPdfBytes;
				this.pdfDoc = await PDFDocument.load(this.existingPdfBytes);
				await this.pdfDoc.save();

				this.totalPages = this.pdfDoc.getPageCount();
				if(this.totalPages > 0)
				{
					this.isShowPagination = true;
				}
				this.renderPage(this.currentPage);
			} catch (error) {
				console.error('Error modifying PDF:', error);
			}
		},
        // Convert base64 string to Blob
        base64ToBlob(base64, type = 'application/pdf') {
            const binaryString = atob(base64); // Decode the base64 string
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return new Blob([bytes], { type });
        },
		async renderPage(pageNumber, from = '') {
			try {
				await this.$nextTick();

				const canvas = this.$refs.pdfCanvas;
				if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
					// await this.retryGetCanvas();
					throw new Error('Canvas element is not found or is not a valid HTMLCanvasElement.');
				}

				const context = canvas.getContext('2d');
				if (!context) {
					throw new Error('Unable to get 2D context of canvas.');
				}

				const loadingTask = window.pdfjsLib.getDocument({ data: this.existingPdfBytes });
				const pdf = await loadingTask.promise;
				const page = await pdf.getPage(pageNumber);

				const viewport = page.getViewport({ scale: 1 });
				canvas.width = viewport.width;
				canvas.height = viewport.height;

				const renderContext = {
					canvasContext: context,
					viewport: viewport,
				};
				await page.render(renderContext).promise;

				if (from != "next" && from != "previous" && from != "add-page") {
					await this.renderEditAnnotation();
				}

				if(!this.copiedElement) {
					this.resetSelectedElementObjAndId();
				}
			} catch (error) {
				console.error('Error rendering page:', error);
			}
		},
        goToNextPage() {
			if (this.currentPage < this.totalPages) {
				this.currentPage++;
				this.renderPage(this.currentPage, 'next');
			}
		},
		goToPreviousPage() {
			if (this.currentPage > 1) {
				this.currentPage--;
				this.renderPage(this.currentPage, 'previous');
			}
		},
        getAllergyWarning(){
            this.allergyInteractionsData = []
            const medicationIds = this.prescriptionList.map(item => item.medication_id);
            if(medicationIds.length > 0) {
                axios.post("patient/prescriptions/getAllergyWarning", { patient_id: this.$route.params.patient_id, medication_ids:medicationIds})
                    .then((response) => {
                    let detail = response.data.data
                    this.allergyInteractionsData = detail.documentPath
                })
            }
        },
        getDrugInteractions(){
            this.drugInteractionsData = []
            const medicationIds = this.prescriptionList.map(item => item.medication_id);
            if(medicationIds.length > 0) {
                axios.post("patient/prescriptions/getDrugInteractions", { patient_id: this.$route.params.patient_id, medication_ids:medicationIds })
                    .then((response) => {
                    let detail = response.data.data
                    this.drugInteractionsData = detail.documentPath
                })
            }
        },
        getPatientHistory() {
            axios.post("patient/allergies/list", { patient_id: this.$route.params.patient_id })
                .then((response) => {
                let detail = response.data.data;
                this.patientAllergyPapidCodes = detail.map(item => item.pepid_code);
            })
        },
        getPreviousPrescriptionList(){
            this.$store.state.loader = true;
            axios.post('patient/prescriptions/list', {
                patient_id: this.$route.params.patient_id,

            })
            .then((response) => {
                this.$store.state.loader = false;
                if (response.status == 200) {
                    this.previousPrescriptionList = response.data.data
                } else {
                    this.$filters.moshaToast(response.data.message, "error");
                }
            }).catch(error => {
                this.$store.state.loader = false;
                if (error.response.status === 422) {
                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    console.log(Object.values(error.response.data.data).join(', '))
                } else {
                    this.$filters.moshaToast(error.response.data.message, "error");
                }
            });
        },
        getUserMedicationIds() {
            axios.post("patient/prescriptions/list", {patient_id: this.$route.params.patient_id})
            .then((response) => {
                this.userMedicationIds = response.data.data.map(item => item.medication_id);
            });
        },
        getApiData(item) {
            const medicationIds = this.prescriptionList.map(item => item.medication_id);
            axios.post("json_autocomplete/allergy-interactions", {allergies: this.patientAllergyPapidCodes, planned_drugs: medicationIds})
            .then((response) => {
                this.allergyInteractions = response.data.flat();
            });

            axios.post("json_autocomplete/drug-interactions", {ids: [...this.userMedicationIds, ...medicationIds]})
            .then((response) => {
               this.drugInteractions = response.data;
            });

            axios.post("json_autocomplete/drug-data", {id: item.id})
            .then((response) => {
               item.drug_data = response.data;
            });
        },
        getFormattedTodayDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        formatDurationUnit(durationUnit) {
            return durationUnit.replace(/\(s\)/g, '').trim().charAt(0).toUpperCase() + durationUnit.slice(1);
        },
        isRefillDateActive(next_refill_date) {
            const today = new Date();
            // const formattedTodayDate = today.toISOString().split('T')[0]; // "YYYY-MM-DD"
            return new Date(next_refill_date) >= today;
        },
        getCurrrentUserName() {
            // this.currentUserName = localStorage.getItem('authUserName');
            return localStorage.getItem('authUserName');
        },
        formattedText(text){
            text.replace(/\n/g, '<br>')
        },
        cancelModel() {
            window.$('#prescription-fax-model').modal('hide');
        },
        sendFaxModel() {
            this.v$.faxForm.$reset();
            this.v$.emailForm.$reset();
            this.v$.mailForm.$reset();
            Object.assign(
                this.$store.state.prescriptions.faxForm,
                this.$store.state.prescriptions.defaultexportfrm
            );
            Object.assign(
                this.$store.state.prescriptions.emailForm,
                this.$store.state.prescriptions.defaultexportfrm
            );
            Object.assign(
                this.$store.state.prescriptions.mailForm,
                this.$store.state.prescriptions.defaultexportfrm
            );
            this.tagSpecialistLabel = ''
            this.specialist_option.map((item) => {
                item.checked = false;
            });

            this.isFaxFormVisible=true
            this.isEmailFormVisible=false
            this.isMailFormVisible=false
            window.$('#add-exportconsultation-model').modal('show');
        },
        changeExportOpt(type){
            this.isFaxFormVisible=false
            this.isEmailFormVisible=false
            this.isMailFormVisible=false

            if (type == 'fax') {
                this.isFaxFormVisible=true
            } else if (type == 'email') {
                this.isEmailFormVisible=true
            } else if (type == 'mail') {
                this.isMailFormVisible=true
            }
        },
        selectedSpecialist(value){
            this.specialist_option = value;
            const specialist_list = this.specialist_option.map((item) => {
                return (item.checked == true) ? item.id : null;
            });

            this.faxForm.specialist_id = specialist_list.filter(n => n);
            if (this.faxForm.specialist_id.length > 0) {
                if (this.faxForm.specialist_id.length == 1) {
                    this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist Selected`;
                } else {
                    this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist(s) Selected`;
                }
            } else {
                this.tagSpecialistLabel = "";
            }
        },
        formatFaxNumber(){
            const { fax_number, phoneNumbers } = this.faxForm;

            // Call the globally defined formatFaxNumber
            const result = this.$filters.formatFaxNumber(fax_number, phoneNumbers);

            // Check the length of phoneNumbers after formatting
            if (result.phoneNumbers.length > 4) {
                this.faxForm.fax_number = ''; // Clear the fax_number
                this.$filters.moshaToast("You cannot add more than five recipient fax numbers for sending.", "error");
                return false; // Prevent further processing
            }

            // Update the model based on the result
            this.faxForm.fax_number = result.faxNumber;
            this.faxForm.phoneNumbers = result.phoneNumbers;
        },
        removeSelectedFax(numberToRemove){
            const updatedPhoneNumbers = this.faxForm.phoneNumbers.filter(phone => phone !== numberToRemove);
            this.faxForm.phoneNumbers = updatedPhoneNumbers
        },
        handleKeyDown(event) {
            if (event.key == 'Backspace') {
                this.faxForm.fax_number = this.faxForm.fax_number.slice(0, -1);
                event.preventDefault();
            }
        },
        closeExportModel(){
            window.$("#add-exportconsultation-model").modal("hide");
        },
        exportSubmit(){
            let form = null
            let formName = 'faxForm'
            if (this.isFaxFormVisible) {
                form = { ...this.faxForm };
                form.type = "fax"
                formName = 'faxForm';
                form.fax_number = this.faxForm['phoneNumbers'].join(', ');
            } else if(this.isEmailFormVisible){
                form = this.emailForm
                form.type = "email"
                formName = 'emailForm';
            } else if (this.isMailFormVisible){
                form = this.mailForm
                form.type = "mail"
                formName = 'mailForm';
            }
            form.ids = this.selectedItems
            form.patient_id = (this.$route.params.patient_id) ? this.$route.params.patient_id : null;
            form.combinedPDF = this.documentPath
            this.v$[formName].$validate();

            if (!this.v$[formName].$error) {
                this.faxLoader = true

                let url = "patient/prescriptions/export"
                    axios.post(url, form)
                    .then((response) => {
                            this.faxLoader = false
                            if (response.status == 200) {
                                if (formName == 'faxForm') {
                                    let allSuccess = false
                                    for (const detail of response.data.data) {
                                        if (detail.status == 'success'){
                                            allSuccess = true
                                            this.$filters.moshaToast(detail.msg, "success");
                                        } else {
                                            this.$filters.moshaToast(detail.msg, "error");
                                        }
                                    }
                                    if (allSuccess == 'success') {
                                        window.$("#add-exportconsultation-model").modal("hide");
                                        this.$router.push({ name: 'PatientEChartPrescriptionList', params: {patient_id: this.$route.params.patient_id } });
                                    }
                                } else {
                                    this.$filters.moshaToast(response.data.message, "success");
                                    window.$("#add-exportconsultation-model").modal("hide");
                                    this.$router.push({ name: 'PatientEChartPrescriptionList', params: {patient_id: this.$route.params.patient_id } });
                                }
                            } else {
                                this.$filters.moshaToast(response.data.message, "error");
                            }
                        }).catch(error => {
                            this.faxLoader = false
                            this.$filters.moshaToast(error.response.data.message, "error");
                        });
            } else {
                this.faxLoader = false
                for (const [key] of Object.entries(this.v$[formName])) {
                    if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                        const myEl = document.getElementById(key);
                        if (myEl) {
                            this.$smoothScroll({
                                scrollTo: myEl,
                            });
                            break;
                        }
                    }
                }
            }

        },
        getSpecialistList(){
            axios.post("custom-specialists/list", {specialist_type:this.form.service_id})
                .then((response) => {
                    if (response.status == 200) {
                        const details = response.data.data;
                        details.sort((a, b) => {
                            return (b.is_preferred_specialist == true) - (a.is_preferred_specialist == true);
                        });

                        const specialistTempData = details.map((item) => {
                            let name = item.name;
                            if (item.is_preferred_specialist) {
                                name += "<p class='preferred-label float-end mb-0 mt-10'>(Preferred)</p>"
                            }
                            if (item.address){
                                name += "<p class='address-line mb-0'>"+item.address+"</p>"
                            }
                            return { id: item.id, name: item.name, checked: false, displayLabel: name };
                        });
                        this.specialist_option = specialistTempData;
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.loader = false;
                    console.log(error)
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
        },
        async viewPdfInNewTab() {
            // Save the modified PDF and create a Blob from it
            const pdfBytes = await this.pdfDoc.save();
            const modifiedBlob = new Blob([pdfBytes], { type: 'application/pdf' });
            const modifiedBlobUrl = URL.createObjectURL(modifiedBlob); // Create a URL for the modified Blob

            // Open the modified PDF in a new tab
            window.open(modifiedBlobUrl, '_blank');
		},
    },
    computed: {
        form() {
            return this.$store.state.prescriptions.form;
        },
        faxForm(){
            return this.$store.state.prescriptions.faxForm;
        },
        emailForm(){
            return this.$store.state.prescriptions.emailForm;
        },
        mailForm(){
            return this.$store.state.prescriptions.mailForm;
        },
    },
    mounted(){
        const pdfScript = document.createElement('script');
        pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.min.js';
        // Letest
        // pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.min.js';
        // client
        // pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.min.js';

        pdfScript.onload = () => {
            const workerScript = document.createElement('script');
            workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
            // Letest
            // workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.worker.min.js';
            // client
            // workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf-lib/1.17.1/pdf-lib.min.js';

            workerScript.onload = () => {
                // Initialize your PDF logic here after both scripts are loaded
                // this.edit();  // Call this.edit() after both scripts are fully loaded
                if (this.$route.name == 'PatientEChartPrescriptionView') {
                    this.edit();
                }
            };

            document.head.appendChild(workerScript);
        };

        document.head.appendChild(pdfScript);
        this.fetchPatientDetail()
        // this.getProvider()
        this.getPreferredPharamcy()
        this.getPatientHistory()
        this.getUserMedicationIds()
        this.getPreviousPrescriptionList()
        this.getSpecialistList()

        document.addEventListener('click', this.handleClickOutside);
    },
    validations() {
        return {
            faxForm: this.$store.state.prescriptions.validationRules.faxForm,
            emailForm: this.$store.state.prescriptions.validationRules.emailForm,
            mailForm: this.$store.state.prescriptions.validationRules.mailForm,
        };

    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }
})
</script>