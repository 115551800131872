<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies">
			<div class="a_header">
				<div>
					<div class="search me-0">
						<img class="serch_icon" src="/images/search-icon.svg" alt="">
						<input type="text" class="form-control" placeholder="Search Specialist" v-model="specialist_search" @input="handleInput" @keydown.enter="specialistSearch" ref="globalSearch">
					</div>
					<span class="position-relative">
						<button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
						<button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length }}</button>
						<CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getAllCustomSpecialist"></CustomFilter>
					</span>
				</div>
				<button class="comman_btn" @click="add"> Add Specialist </button>
			</div>
			<div class="table-responsive specialist-list">
				<table class="table">
					<thead>
						<tr>
							<th scope="col" class="hover_unset w-30px cursor-auto"></th>
							<th scope="row" @click="getSpecialistOrderBy('specialist_type', $event)" class="active">Service Type <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="row" @click="getSpecialistOrderBy('name', $event)">Specialist <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="row" @click="getSpecialistOrderBy('address', $event)">Address <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="row" @click="getSpecialistOrderBy('phone', $event)">Phone <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="row" @click="getSpecialistOrderBy('fax', $event)">Fax <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="row" @click="getSpecialistOrderBy('notes', $event)">Note <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="row" class="hover_unset cursor-auto"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in this.allCustomSpecialist" :key="index" :class="{ 'active': item.is_preferred_specialist === 1 }">
							<td></td>
							<td>{{ item.specialist_type }}</td>
							<td>{{ item.name }}</td>
							<td>{{ item.address }}</td>
							<td>{{ item.phone }}</td>
							<td>{{ item.fax }}</td>
							<td>{{ item.notes }}</td>
							<td><img src="/images/dots-icon.svg" class="dot_icon" @click="editSpecialist(item.id)"></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<div class="modal fade" id="add-specialist-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl" style="max-width: calc(var(--scale-ratio) * 1190px);">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
					<div class="box">
						<h2 class="popup_title">{{ this.popupHeadingText }}</h2>
						<div class="address_line">
							<label class="text">Set this specialist as preferred </label>
							<input class="form-check-input m-0 align-middle" type="checkbox" id="checkboxNoLabel" v-model="form.is_preferred_specialist">
						</div>
						<div class="custom-form">
							<div class="input_box">
								<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.name">
								<label class="form-label" for="typeunique_idX-2">Specialist Name <span class="asterisk-red">*</span></label>
								<ValidationErrorMessageList :errors="v$.form.name.$errors" />
							</div>
							<div class="input_box">
								<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.specialist_type">
								<label class="form-label" for="typeunique_idX-2">Specialist Service Type <span class="asterisk-red">*</span></label>
								<ValidationErrorMessageList :errors="v$.form.specialist_type.$errors" />
							</div>
							<div class="input_box">
								<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.phone">
								<label class="form-label" for="typeunique_idX-2">Phone Number <span class="asterisk-red">*</span></label>
								<ValidationErrorMessageList :errors="v$.form.phone.$errors" />
							</div>
							<div class="input_box">
								<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.fax">
								<label class="form-label" for="typeunique_idX-2">Fax Number <span class="asterisk-red">*</span></label>
								<ValidationErrorMessageList :errors="v$.form.fax.$errors" />
							</div>
							<div class="input_box">
								<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.address">
								<label class="form-label" for="typeunique_idX-2">Address <span class="asterisk-red">*</span></label>
								<ValidationErrorMessageList :errors="v$.form.address.$errors" />
							</div>
							<div class="input_box">
								<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.city">
								<label class="form-label" for="typeunique_idX-2">City <span class="asterisk-red">*</span></label>
								<ValidationErrorMessageList :errors="v$.form.city.$errors" />
							</div>
							<div class="input_box">
								<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.province">
								<label class="form-label" for="typeunique_idX-2">Province <span class="asterisk-red">*</span></label>
								<ValidationErrorMessageList :errors="v$.form.province.$errors" />
							</div>
							<div class="input_box">
								<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="form.zip" maxlength="10">
								<label class="form-label" for="typeunique_idX-2">Postal Code <span class="asterisk-red">*</span></label>
								<ValidationErrorMessageList :errors="v$.form.zip.$errors" />
							</div>
						</div>
						<div class="btn_part text-center">
							<button class="comman_brdr_btn big_btn mx36" @click="closeModal()"> Cancel </button>
							<button class="comman_btn big_btn mx36" @click="submitSpecialistForm">{{ this.buttonText }}
								<img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import sidebar from './consultation/sidebar.vue';
import axios from "@/scripts/axios.js";
import CustomFilter from '../../base/CustomFilter.vue';
import ValidationErrorMessageList from '../../base/ValidationErrorMessageList.vue';
import useVuelidate from "@vuelidate/core";

export default {
		data() {
			return {
				allCustomSpecialist: [],
				specialistFormProp: {
					is_preferred_specialist: false,
					name: '',
					specialist_type: null,
					phone: null,
					fax: null,
					address: null,
					city: null,
					province: null,
					zip: null,
					patient_id: (this.$route.params.patient_id) ? this.$route.params.patient_id : null,
					id: null,
				},
				loader: false,
				action: "Add",
				buttonText: "Add",
				popupHeadingText: "Add New Specialist",
				specialist_search: '',
				filterOptions:[
					{ value: "specialist_type", title: "Service Type" },
					{ value: "name", title: "Specialist" },
					{ value: "address", title: "Address" },
					{ value: "phone", title: "Phone" },
					{ value: "fax", title: "Fax" },
					{ value: "notes", title: "Note" },
				],
				isShowCustomFilter: false,
				filterList:[],
				searchTimeout: null,
			}
		},
		components: {
			sidebar,
			CustomFilter,
			ValidationErrorMessageList
		},
		setup: () => {
			return { v$: useVuelidate() };
		},
		validations() {
			return this.$store.state.customSpecialist.validationRules;
		},
		methods:{
			handleInput() {
				// Clear the previous timer if it exists
				clearTimeout(this.searchTimeout);

				// Start a new timer that triggers after 2 seconds of idle time
				this.searchTimeout = setTimeout(() => {
					this.specialistSearch();
				}, 1000); // 1-second idle time
			},
			handleClickOutside(event) {
				if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
					this.getAllCustomSpecialist();
					this.isShowCustomFilter = false;
				}

				// this.specialist_search = '';
				// this.getAllCustomSpecialist();
			},
			getSpecialistOrderBy(orderColumnName, event) {
				event.stopPropagation();

                const th = event.target;
                this.form.orderByColumnName = orderColumnName;
                if (th.classList.contains('active')) {
                    th.classList.remove('active');
                    this.form.orderBy = 'asc';
                } else {
                    th.classList.add('active');
                    this.form.orderBy = 'desc';
                }
                this.getAllCustomSpecialist();
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
            },
            handleCustomField(values){
                console.log(values)
                this.filterList = values;
                let advanceFilter = {};
                values.forEach(item => {
                    advanceFilter[item.field] = item.value;
                });
                this.form.advanceFilter = advanceFilter;
            },
            specialistSearch()
            {
                clearTimeout(this.searchTimeout);
                this.getAllCustomSpecialist();
            },
            closeModal() {
                window.$("#add-specialist-model").modal("hide");
            },
            capitalizeData(data) {
                return data.map(item => ({
                    ...item,
                    name: item.name ? this.$filters.capitalizeFirstLetter(item.name) : null,
                    specialist_type: item.specialist_type ? this.$filters.capitalizeFirstLetter(item.specialist_type) : null,
                }));
            },
            submitSpecialistForm() {
                if(this.action == 'Add')
                    {
                        this.v$.$validate();
                        if (!this.v$.$error) {
                            this.loader = true;
                            axios.post("custom-specialists/store", this.form)
                                .then((response) => {
                                    if (response.status == 200) {
                                        
                                        this.getAllCustomSpecialist();                                    
                                        this.loader = false;
                                        window.$("#add-specialist-model").modal("hide");
                                        this.$filters.moshaToast(response.data.message, "success");

									} else {
										this.$filters.moshaToast(response.data.message, "error");
									}
								}).catch(error => {
									this.loader = false;
									if (error.response.status === 422) {
										this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
										console.log(Object.values(error.response.data.data).join(', '))
									} else {
										this.$filters.moshaToast(error.response.data.message, "error");
									}
								});
						}
						else
						{
							for (const [key] of Object.entries(this.v$.form)) {
								if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
									const myEl = document.getElementById(key);
									if (myEl) {
										this.$smoothScroll({
											scrollTo: myEl,
										});
										break;
									}
								}
							}
						}
					}
					else
					{
						this.v$.$validate();
						if (!this.v$.$error) {
							this.loader = true;
							axios.post('custom-specialists/update', this.form)
							.then((response) => {
								if (response.status == 200) {
									this.getAllCustomSpecialist();
									this.loader = false;
									this.$filters.moshaToast(response.data.message, "success");
									window.$("#add-specialist-model").modal("hide");
								} else {
									console.log('submit-note-error-response:- ', response);
									this.$filters.moshaToast(response.data.message, "error");
								}
							}).catch(error => {
								this.loader = false;
								if (error.response.status === 422) {
									console.log('submit-note-catch-error-response:- ', error);
									this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
									console.log(Object.values(error.response.data.data).join(', '))
								} else {
									console.log('submit-note-else-catch-error-response:- ', error);
									this.$filters.moshaToast(error.response.data.message, "error");
								}
							});
						}
						else
						{
							for (const [key] of Object.entries(this.v$.form)) {
								if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
									const myEl = document.getElementById(key);
									if (myEl) {
										this.$smoothScroll({
											scrollTo: myEl,
										});
										break;
									}
								}
							}
						}
					}
			},
			editSpecialist(specialistId) {
				this.loader = true;
				axios.post("custom-specialists/retrieve", { id: specialistId })
				.then((response) => {
					if (response.status == 200) {
						this.loader = false;
						this.popupHeadingText = "Specialist Details";
						this.buttonText = "Update";
						this.action = "Update";
						const editResponseData = response.data.data;
						
						this.form.id = editResponseData.id;
						this.form.is_preferred_specialist = (editResponseData.is_preferred_specialist == 1)? true : false;
						this.form.name = editResponseData.name;
						this.form.specialist_type = editResponseData.specialist_type;
						this.form.phone = editResponseData.phone;
						this.form.fax = editResponseData.fax;
						this.form.address = editResponseData.address;
						this.form.city = editResponseData.city;
						this.form.province = editResponseData.province;
						this.form.zip = editResponseData.zip;
						window.$("#add-specialist-model").modal("show");

					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
			},
			getAllCustomSpecialist() {
				this.$store.state.loader = true;
				axios.post("custom-specialists/list", {
					keyword:this.form.keyword,
					advanceFilter:this.form.advanceFilter,
					specialist_search:this.specialist_search,
					orderBy:this.form.orderBy,
					orderByColumnName:this.form.orderByColumnName,
				} )
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						this.allCustomSpecialist = this.capitalizeData(response.data.data);
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
			},
			add(){
				this.resetForm();
				window.$("#add-specialist-model").modal("show");
			},            
			resetForm(){
				Object.assign(
					this.$store.state.customSpecialist.form,
					this.$store.state.customSpecialist.defaultFormData,
				);
				this.v$.$reset();
				this.buttonText = "Add";
				this.action = "Add";
				this.popupHeadingText = "Add New Specialist";

			}
		},
		mounted() {
			this.resetForm();
			document.addEventListener('click', this.handleClickOutside);
			this.getAllCustomSpecialist();
		},
		computed: {
			form() {
				return this.$store.state.customSpecialist.form;
			},
		}
}
</script>