<template>
	<div class="row patient_chart patient_qry_tol">
		<div class="col-3 col-md-3 col-lg-3 left_side_bar px-0">
			<div>
				<div class="top_txt">
					<button class="comman_btn fs-20 pt-15 pb-15 w-100 mb-20" @click="addFilter">Add Filter</button>
				</div>
				<div class="multi_addFilter">
					<slot v-for="(filterSet, index) in getFilterSetsData" :key="index">
						<button class="comman_brdr_btn big_btn w-100 px-2 h-60" v-if="selectedFilterSetId === null" @click="selectFilterSet(filterSet.filter_set_id)">{{ filterSet.filter_set_name }}</button>
					</slot>
				</div>
				<div v-if="selectedFilterSetId !== null || this.savedQueries.length > 0" class="FilterSet_list mt-20 mb-20">
					<span class="popup_link fw-600 mt-7 ml-40" @click="removeAll">Remove All</span>
					<ul class="filter_list mb-0 mt-10" v-if="savedQueries.length > 0">
						<li v-for="(query, index) in savedQueries" :key="index">
							<span>{{ query.parameter.toUpperCase() }} {{ query.operator }} {{ capitalizeFirstLetter(query.value) }}</span>
							<img src="/images/close_sign.svg" class="img-fluid cursor-pointer" @click="removeQuery(index)">
						</li>
					</ul>
				</div>
			</div>

			<!-- <div class="botm_txt top_bdr" v-if="((getFilterSetsData.length > 0 && selectedFilterSetId !== null) || this.savedQueries.length > 0)"> -->
			<div class="filtersetsdata-btm-list" :class="{ 'botm_txt top_bdr': this.savedQueries.length > 0}">
				<div class="mb-40" v-if="getFilterSetsData.length > 0 && selectedFilterSetId !== null && isEditSetName === false">
					<div v-for="filterSet in getFilterSetsData" :key="filterSet.filter_set_id"
						class="d-flex align-items-center justify-content-between fw-600 l-h-n">
						<span v-if="filterSet.filter_set_id === selectedFilterSetId" class="ml-20 fs-18">{{
							filterSet.filter_set_name }}</span>
						<span v-if="filterSet.filter_set_id === selectedFilterSetId" class="popup_link mr-10"
							@click="isEditSelectedSetsName(filterSet.filter_set_name)">Edit</span>
					</div>
				</div>
				<div v-if="this.savedQueries.length > 0">
					<div class="selectedfilter_name ">
						<input v-if="isEditSetName" type="text" class="w-100 px-2 fs-18 h-60 text-center"
							:ref="filter_set_name" v-model="filter_set_name" placeholder="Name this filter set">
					</div>
					<button class="comman_btn big_btn w-100 mt-30 px-2" @click="saveFilterSet">Save Filter Set</button>
					<button class="comman_brdr_btn big_btn w-100 asterisk-red mt-33 px-2"
						v-if="isShowDeleteFilterBtn && selectedFilterSetId !== null" @click="deleteFilterSets">
						Delete Filter Set
					</button>
				</div>
			</div>
		</div>
		<div class="col-9 col-md-9 col-lg-9 right_side_bar">
			<div class="exportCSV_btn text-end" v-if="isShowExportCsvBtn">
				<button class="comman_brdr_btn big_btn mb-20" :class="{ 'active': selectedItems.length > 0 }"
                    @click="exportCSV">Export CSV</button>
			</div>
			<div class="header_qr_tl d-flex align-items-center mb-20" v-if="showQueryForm">
				<div class="patient_query_dmgrpic">
					<div class="heading_selact_drodwn mr-55">
						<CustomDropDown :options="this.moduleOpt" :initialValue="this.module_text"
							@item-selected="handleItemSelected" :fieldName="'module_name'" v-model="query.module"
							:ref="`module_name`" divClass="form-label">
						</CustomDropDown>
					</div>
				</div>
				<div class="patient_query_medical">
					<div class="heading_selact_drodwn">
						<CustomDropDown :options="this.parameterOpt" :initialValue="this.defaultParameter"
							@item-selected="handleItemSelected" :fieldName="'parameter'" divClass="form-label">
						</CustomDropDown>
					</div>
				</div>
				<div class="patient_query_compareOpt">
					<div class="patient_query_compareOpt">
						<div class="heading_selact_drodwn com_sign mx40">
							<CustomDropDown :options="this.compareOpt" :initialValue="this.defaultCompareText"
								@item-selected="handleItemSelected" :fieldName="'operator'"
								:defaultSelectedClass="`active`" :ref="`operator`" divClass="form-label">
							</CustomDropDown>
						</div>
					</div>
				</div>
				<div class="ptnt_query_rsult mr-50">
					<input type="text" name="query_filter_values" class="form-control rsult_input focus_none"
						v-model="query.value">
				</div>
				<div>
					<span class="popup_link fw-600 fs-20 mr-40" @click="cancelQueryForm('on-cancel')">Cancel</span>
					<span class="popup_link fw-600 fs-20" @click="getQueryData(1, 'on-add-filter')">Add</span>
				</div>
			</div>
			<div class="table-responsive patient_query_table">
				<table class="table">
					<thead>
						<tr>
							<th class="hover_unset w-50px"><input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll"></th>
							<th scope="col">NAME </th>
							<th scope="col">GENDER</th>
							<th scope="col">AGE</th>
							<th scope="col">HIN</th>
							<th scope="col">RESIDENTIAL CITY </th>
							<th scope="col">FAMILY DOCTOR </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(demographic, index) in listOfDemographicData" :key="index"
							:class="{ 'active': (selectChk[demographic.id] || selectedItems.includes(demographic.id)) }">
							<td>
								<input class="form-check-input" type="checkbox" :checked="isChecked(demographic.id)"
									v-model="selectChk[demographic.id]" @change="checkSelect(demographic.id)">
							</td>
							<td>
								<router-link :to="{ name: 'PatientChart', params: { patient_id: demographic.id, } }"
									target="_blank" class="patient_q_name cursor-pointer">{{ demographic.patient_name ? demographic.patient_name : ''
									}}</router-link>
							</td>
							<td>{{ demographic.gender? demographic.gender : '' }}</td>
							<td>{{ demographic.age? demographic.age : 0 }}</td>
							<td>{{ demographic.hin? demographic.hin : '' }}</td>
							<td>{{ demographic.residential_city? demographic.residential_city : '' }}</td>
							<td>{{ demographic.family_doctor_name? demographic.family_doctor_name : '' }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-if="lastPage > 1 && listOfDemographicData.length > 0" class="query-pagination text-center">
				<ul class="page-pagination p-0">
					<li style="margin-right: calc(var(--scale-ratio) * 40px);">
						<img src="/images/left-arrow-pagen.svg" class="img-fluid" v-if="currentPage > 1" @click="getQueryData(currentPage - 1, 'pagination')">
					</li>
					<li>{{ currentPage }}</li>
					<li>/</li>
					<li>{{ lastPage }}</li>
					<li style="margin-left: calc(var(--scale-ratio) * 40px);">
						<img src="/images/right-arrow-pagen.svg" class="img-fluid" v-if="currentPage < lastPage" @click="getQueryData(currentPage + 1, 'pagination')">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
export default {
	components: {
		CustomDropDown
	},
	mounted() {
		this.getFilterSets();
	},
	computed: {
		
	},
	data() {
		return {
			moduleOpt: [
				{ value: "Demographic", title: "DEMOGRAPHIC" },
			],
			module_text: "Select Module",
			parameterOpt: [
				{ value: "Age", title: "AGE" },
				{ value: "Gender", title: "GENDER" },
				{ value: "Residential City", title: "RESIDENTIAL CITY" },
				{ value: "Family Doctor", title: "FAMILY DOCTOR" },
			],
			defaultParameter: "Select Parameter",
			compareOpt: [
				{ value: "=", title: "=" },
				{ value: "<", title: "<" },
				{ value: ">", title: ">" },
			],
			defaultCompareText: '=',

			/* Get, Filter Sets Data */
			getFilterSetsData: [],
			query: {},
			selectedFilterSetId: null,
			listOfDemographicData: [],
			isEditSetName: false,

			savedQueries: [],
			filter_set_name: '',
			
			showQueryForm: false,
			lastPage: null,
			currentPage: null,
			isShowDeleteFilterBtn: false,

			selectAllChk: false,
			selectChk: [],
			allItems: [],
			selectedItems: [],
			isShowExportCsvBtn: true,
			
		}
	},
	methods: {
		exportCSV(){
			let resultArray = [];
			if (this.selectedItems.length === 0) {
				this.$filters.moshaToast("Please select the data row to export.", "error");
				return false;
			}
			else
			{
				for (let itemId of this.selectedItems) {
					let demographic = this.listOfDemographicData.find(demographic => demographic.id === itemId);
					if (demographic) {
						resultArray.push(demographic.id);
					}
				}
	
				this.$store.state.loader = true;
				axios.post('query-tool/export-filter-data', { 'patient_ids': resultArray }, {
					responseType: 'blob'
				})
				.then((response) => {
					this.$store.state.loader = false;
					const blob = new Blob([response.data], { type: 'text/csv' });
	
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = 'filtered-patients-demographic.csv';
	
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
	
					this.selectChk = [];
					this.selectedItems = [];
					this.selectAllChk = false;
				}).catch(error => {
					this.$store.state.loader = false;
					console.log(error)
				});
			}
		},
		checkSelect(id) {
			if (this.selectChk[id]) {
				this.selectedItems.push(id)
			} else {
				const index = this.selectedItems.indexOf(id);
				if (index !== -1) {
					this.selectedItems.splice(index, 1);
				}
			}

			if (this.selectedItems.length == this.allItems.length) {
				this.selectAllChk = true;
			} else if (this.selectedItems.length == 0) {
				this.selectAllChk = false;
			} else {
				this.selectAllChk = false;
			}
			this.cancelQueryForm();
		},
		isChecked(id) {
			return this.selectedItems.includes(id);
		},
		checkSelectAll() {
			if (this.selectAllChk) {
				this.selectedItems = [...this.allItems];
			} else {
				this.selectedItems = [];
				this.selectChk = [];
			}
			this.cancelQueryForm();
		},
		capitalizeFirstLetter(value) {
			if (!value) return '';
			return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
		},
		getInitialQuery() {
			return {
				filter_set_id: null,
				module: '',
				parameter: '',
				operator: '=',
				value: '',
			};
		},
		getFilterSets() {
			this.$store.state.loader = true;
				axios.get("query-tool/get-filter-sets")
				.then((response) => {
					if (response.status == 200) {
						this.$store.state.loader = false;
						this.getFilterSetsData = response.data.data;
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						console.log(Object.values(error.response.data.data).join(', '))
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		deleteFilterSets() {
			this.$store.state.loader = true;
			axios.post("query-tool/delete-filter-set", { filter_set_name: this.filter_set_name, filter_set_id: this.selectedFilterSetId })
				.then((response) => {
					if (response.status == 200) {
						this.$store.state.loader = false;
						this.getFilterSetsData = [];
						this.savedQueries = [];
						this.isShowDeleteFilterBtn = false;
						this.selectedFilterSetId = null;
						this.listOfDemographicData = [];
						this.getFilterSets();
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		selectFilterSet(filterSetId) {
			const selectedFilterSet = this.getFilterSetsData.find(
				(filterSet) => filterSet.filter_set_id === filterSetId
			);
			if (selectedFilterSet) {
				this.savedQueries = selectedFilterSet.query;
				this.fetchSelectedSetsQueries();
				this.isShowDeleteFilterBtn = true;
				this.selectedFilterSetId = filterSetId;
				this.isEditSetName = false;
				this.filter_set_name = selectedFilterSet.filter_set_name;
			}
		},
		fetchSelectedSetsQueries() {
			const normalizedQueries = this.savedQueries.map(query => {
				const normalizedQuery = { ...query };

				if (["Gender", "Residential City", "Family Doctor"].includes(query.parameter)) {
					normalizedQuery.value = query.value.toLowerCase();
				}

				return normalizedQuery;
			});

			this.$store.state.loader = true;
			axios.post("query-tool/get-filter-data", { query: normalizedQueries })
			.then((response) => {
				this.$store.state.loader = false;
				if (response.status == 200) {
					this.listOfDemographicData = response.data.data.data;
					this.allItems = [];
					this.listOfDemographicData.forEach(item => {
						this.allItems.push(item.id)
					});
					this.lastPage = response.data.data.last_page;
					this.currentPage = response.data.data.current_page;
					// this.$filters.moshaToast(response.data.message, "success")
				} else {
					this.$filters.moshaToast(response.data.message, "error")
				}
			}).catch(error => {
				this.$store.state.loader = false;
				if (error.response.status === 422) {
					this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
				} else {
					this.$filters.moshaToast(error.response.data.message, "error");
				}
			});
		},
		isEditSelectedSetsName(name) {
			this.isEditSetName = true;
			this.filter_set_name = name;
		},
		addFilter() {
			if(this.selectedFilterSetId === null)
			{
				this.getFilterSetsData = [];
				this.filter_set_name = '';
			}
			this.showQueryForm = true;
			this.selectAllChk = false;
			this.selectChk = [];
			this.selectedItems = [];
			this.isShowExportCsvBtn = false;
			this.query = this.getInitialQuery();
		},
		setOperatorByValue(value) {
			const selectedOption = this.compareOpt.find(opt => opt.value === value);

			if (selectedOption) {
				this.query.operator = selectedOption.title;
				this.defaultCompareText = selectedOption.title;
			}
		},
		handleItemSelected(obj) {
			if (obj.fieldName === 'module_name') {
				this.query.module = obj.item.title;
			}
			if (obj.fieldName === 'parameter') {
				if(obj.item.value == "Gender" || obj.item.value == "Residential City" || obj.item.value == "Family Doctor")
				{
					this.compareOpt = [
						{ value: "=", title: "=" },
					];
				}
				else
				{
					this.compareOpt = [
						{ value: "=", title: "=" },
						{ value: "<", title: "<" },
						{ value: ">", title: ">" },
					];
				}
				this.query.parameter = obj.item.title;
				this.setOperatorByValue("=");
			}
			if (obj.fieldName === 'operator') {
				this.query.operator = obj.item.title;
				this.defaultCompareText = obj.item.title;
			}
		},
		validateForm() {
			const stringFields = ["GENDER", "RESIDENTIAL CITY", "FAMILY DOCTOR"];
			const numberFields = ["AGE"];

			if (stringFields.includes(this.query.parameter)) {
				const containsNumbers = /\d/;
				if (this.query.operator !== "=") {
					this.$filters.moshaToast("Operator is not valid for selected parameter.", "error")
					return false;
				}
				if(containsNumbers.test(this.query.value))
				{
					this.$filters.moshaToast("The value for the selected parameter must be a string, not a number.", "error")
					return false;
				}
				const lowerCaseValue = this.query.value.toLowerCase();
				if(lowerCaseValue != "")
				{
					const validGenders = new Set([
						'm', 'f', 'o', 't',
						'male', 'female', 'other', 'trans'
					]);
					if(this.query.parameter == "GENDER" && !validGenders.has(lowerCaseValue))
					{
						this.$filters.moshaToast("The value for the GENDER parameter must be one of M, F, O, T, or their full-text equivalents (male, female, other, trans).", "error");
						return false;
					}
				} else {
					this.$filters.moshaToast("Please enter a value for the filter.", "error");
					return false;
				}
			}

			if (numberFields.includes(this.query.parameter)) {
				if (!["=", "<", ">"].includes(this.query.operator) && (typeof this.query.value != "number") || isNaN(this.query.value)) {
					this.$filters.moshaToast("The age parameter must be a numeric value.", "error")
					return false;
				}
			}

			if (!this.query.module) {
				this.$filters.moshaToast("Please select a module.", "error")
				return false;
			}
			if (!this.query.parameter) {
				this.$filters.moshaToast("Please select a parameter.", "error")
				return false;
			}
			if (!this.query.operator) {
				this.$filters.moshaToast("Please select a comparison operator.", "error")
				return false;
			}
			if (!this.query.value) {
				this.$filters.moshaToast("Please enter a value.", "error")
				return false;
			}
	
			const normalizedQuery = { ...this.query };
			if (["Gender", "Residential City", "Family Doctor"].includes(this.query.parameter)) {
				normalizedQuery.value = this.query.value.toLowerCase();
			}
			this.savedQueries.push(normalizedQuery);
			return true;
		},
		getQueryData(page = 1, from = '') {
			if(from !== "pagination" && from !== "cancelQueryForm" && from !== "on-remove")
			{
				if(!this.validateForm())
				{
					return false;	
				}
				else
				{
					this.$store.state.loader = true;
					axios.post("query-tool/get-filter-data", { query: this.savedQueries, page: page, limit: 10 })
						.then((response) => {
							this.$store.state.loader = false;
							if (response.status === 200) {
								if (from !== "pagination" && from !== "on-add-filter") {
									this.getFilterSets();
								}
								this.cancelQueryForm();
								if(response.data.data.data && Array.isArray(response.data.data.data))
								{
									if(response.data.data.data.length === 0){
										this.$filters.moshaToast('No data found.', "error");
									}
								}
								if(this.selectedFilterSetId === null)
								{
									this.getFilterSetsData = [];
									this.filter_set_name = '';
								}
								this.listOfDemographicData = response.data.data.data;
								this.allItems = [];
								this.listOfDemographicData.forEach(item => {
									this.allItems.push(item.id)
								});
								this.isEditSetName = true;
								this.lastPage = response.data.data.last_page;
								this.currentPage = response.data.data.current_page;
								// this.$filters.moshaToast(response.data.message, "success");
							} else {
								this.$filters.moshaToast(response.data.data.message, "error");
							}
						})
						.catch(error => {
							this.$store.state.loader = false;
							console.log('getQueryData-response-error:- ', error);
							if (error.response && error.response.status === 422) {
								this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
								console.log(Object.values(error.response.data.data).join(', '));
							} else {
								this.$filters.moshaToast(error.response.data.message, "error");
							}
						});
				}
			}
			else
			{
				// if(!this.validateForm())
				// {
				// 	return false;	
				// } else {
					this.$store.state.loader = true;
					axios.post("query-tool/get-filter-data", { query: this.savedQueries, page: page, limit: 10 })
						.then((response) => {
							this.$store.state.loader = false;
							if (response.data.status === 200) {
								if (from !== "pagination" || from !== "on-remove") {
									this.cancelQueryForm();
								}
								this.listOfDemographicData = response.data.data.data;
								this.lastPage = response.data.data.last_page;
								this.currentPage = response.data.data.current_page;
								// this.$filters.moshaToast(response.data.message, "success");
							} else {
								this.$filters.moshaToast(response.data.data.message, "error");
							}
						})
						.catch(error => {
							this.$store.state.loader = false;
							if (error.response && error.response.status === 422) {
								this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
							} else {
								this.$filters.moshaToast(error.response.data.message, "error");
							}
						});
				// }
			}
		},
		saveFilterSet() {
			if(!this.filter_set_name)
			{
				this.$filters.moshaToast("Required to set filter set name.", "error")
				return false;
			}
			else
			{
				this.$store.state.loader = true;
				axios.post("query-tool/store-filter-set", { query: this.savedQueries, filter_set_name: this.filter_set_name, filter_set_id: this.selectedFilterSetId })
				.then((response) => {
					if (response.status == 200) {
						// this.getFilterSetsData = response.data.data;
						this.getFilterSets();
						this.listOfDemographicData = [];
						this.savedQueries = [];
						this.filter_set_name = '';
						this.selectedFilterSetId = null;
						this.$store.state.loader = false;
						this.isEditSetName = false;
						this.$filters.moshaToast(response.data.message, "success")
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				}).catch(error => {
					this.$store.state.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
			}
		},
		cancelQueryForm(from) {
			this.module_text = "Select Module";
			this.defaultParameter = "Select Parameter";
			this.defaultCompareText = '=';
			this.showQueryForm = false;
			this.isShowExportCsvBtn = true;
			this.query = {};
			if(from == 'on-cancel')
			{
				if (Array.isArray(this.savedQueries))
				{
					const savedQueriesLength = this.savedQueries.length;
					if (savedQueriesLength === 0) {
						this.getFilterSets();	
					}
				}
			}
		},
		removeQuery(index) {
			this.savedQueries.splice(index, 1);
			if (this.savedQueries.length === 0) {
				this.listOfDemographicData = [];
				this.selectedFilterSetId = null;
				this.getFilterSets();
			}
			else
			{
				this.getQueryData(1, 'on-remove');
			}
		},
		removeAll() {
			this.getFilterSets();
			this.selectedFilterSetId = null;
			this.savedQueries = [];
			this.listOfDemographicData = [];
			this.showQueryForm = false;
		},
	},
}
</script>